/* global kakao */
//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, { useEffect, useState, useRef } from "react";
import * as H from '../src/Styles/StyleComponentHan';
import kakaomapPopupLinkKaKaomap from '../src/Images/Etc/kakaomapPopupLinkKaKaomap.jpg';

export default function KakaoMap(props) {
  //KakaoMap맵을 호출할 때 넘겨준
  //마커위치하고 지도크기를 받는다.
  const { constructionPointData} = props;
  //맵을 저장할 useState
  const [kakaoMap, setKakaoMap] = useState(null);
  const [markers, setMarkers] = useState([]);


  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // mapContainerRef는 아래 map을 표현하기 위한 div 참고하기 위해 사용했으며
  // <div에 가면 mapContainerRef를 사용한것을 볼 수 있습니다.  
  const mapContainerRef = useRef();

  useEffect(() => {
    //카카오 스크립트를 가져오고
    const script = document.createElement("script");
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=12636d57aa793fb64d03288ce8aec35d&autoload=false";
    //헤더 태그에 넣는다
    document.head.appendChild(script);
    //스크립트가 다 호출되고 나면
    script.onload = () => {
      //카카오 맵을 호출한다
      kakao.maps.load(() => {
        const options = { 
          center: new kakao.maps.LatLng(36.20802, 127.562835), //카카오맵 중심좌표
          level: 13  // 지도확대레벨
        };
        //해당 옵션을 기준으로 map을 만들고
        const map = new kakao.maps.Map(mapContainerRef.current, options);
        
        //맵을 저장할 useState에 저장시킨다.
        setKakaoMap(map);
      });
    };
  }, [mapContainerRef]);

  useEffect(() => {
    //카카오 맵이 null이라면 아래 스크립트를 실행하지 않는다.
    if (kakaoMap === null) {
      return;
    }

    //기존 마커들 제거
    for ( let i = 0; i < markers.length; i++ ) {
        markers[i].setMap(null);
    }   

    setMarkers([]);

    let bounds = new kakao.maps.LatLngBounds();

    //포지션 및 마커를 임시로 담을 array 배열
    let temp_positions_array = [];
    let temp_makers_array = [];

    //매개변수로 온 시공점 데이터를 가지고 마커위에 팝업을 만든다. : 팝업 구조여기서 짜야함!
    //카카오맵 바로가기 이미지 연결은 추후에 하겠습니다
    constructionPointData.forEach(element => {
      let temp_string = '';
      if(element.construction_point_kakao_url !== ''){
        temp_string = `
        <div class = "kakaomapPopup_LinkBox">
          <a class = "kakaomapPopup_Link01" href="${element.construction_point_kakao_url}" target="_blank">
            <img src =${kakaomapPopupLinkKaKaomap} alt="카카오맵 바로가기">
          </a>
        </div>
        `;
      }
      let placePosition = new kakao.maps.LatLng(element.construction_point_y, element.construction_point_x);
      temp_positions_array.push(
        {
          content: `
            <div class = "kakaomapPopup">
              <p class = "kakaomapPopup_Tit">
                ${element.construction_point_name}
              </p>
              <p class = "kakaomapPopup_Info">
                ${element.construction_point_road_address} ${element.construction_point_detail_address}
              </p>
              <p class = "kakaomapPopup_Info">
                대표번호 ${element.construction_point_phone_number}
              </p>
              ${temp_string}
            </div>
          `,
          latlng: placePosition,
          click_status:element.clickStatus
        }
      )
      // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
      // LatLngBounds 객체에 좌표를 추가합니다
      bounds.extend(placePosition);
    });

    for (let i = 0; i < temp_positions_array.length; i ++) {
/*       const imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png'; // 마커 이미지 url, 스프라이트 이미지를 씁니다
      const imageSize = new kakao.maps.Size(36, 37);  // 마커 이미지의 크기
      const imgOptions =  {
          spriteSize : new kakao.maps.Size(36, 691), // 스프라이트 이미지의 크기
          spriteOrigin : new kakao.maps.Point(0, (i * 46)+10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
          offset: new kakao.maps.Point(13, 37) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
      };
      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions); */

      // 마커를 생성합니다
      const marker = new kakao.maps.Marker({
          map: kakaoMap, // 마커를 표시할 지도
          position: temp_positions_array[i].latlng, // 마커의 위치
          //image: markerImage 
      });

      temp_makers_array.push(marker);
      // 마커에 표시할 인포윈도우를 생성합니다 
      const infowindow = new kakao.maps.InfoWindow({
          content: temp_positions_array[i].content, // 인포윈도우에 표시할 내용
          removable: true
      });

      if(temp_positions_array[i].click_status === true){
        bounds.ha=temp_positions_array[i].latlng.La;
        bounds.oa=temp_positions_array[i].latlng.La;
        bounds.pa=temp_positions_array[i].latlng.Ma;
        bounds.qa=temp_positions_array[i].latlng.Ma;
      }

      // 마커에 mouseover 이벤트와 mouseout 이벤트를 등록합니다
      // 이벤트 리스너로는 클로저를 만들어 등록합니다 
      // for문에서 클로저를 만들어 주지 않으면 마지막 마커에만 이벤트가 등록됩니다
      kakao.maps.event.addListener(
        marker, 
        'click', 
        ()=>{
          infowindow.open(kakaoMap, marker)
        }
      );
    }

    if(bounds.ha !== Infinity){    
      kakaoMap.setBounds(bounds);    
    }
    
    setMarkers(temp_makers_array);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kakaoMap, constructionPointData, props]);

  // 지도 확대, 축소 컨트롤에서 확대 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoom_in = () => {
    kakaoMap.setLevel(kakaoMap.getLevel() - 1);
  }

  const zoom_out = () => {
    kakaoMap.setLevel(kakaoMap.getLevel() + 1);
  }

  return (
    <H.kakaomapWrap>
      <div id="mapContainerRef" ref={mapContainerRef} className="kakaomapBox">
        <div className="kakaomapZoomBox"> 
          <p onClick={zoom_in}>
            <img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png" alt="확대"/>
          </p>  
          <p onClick={zoom_out}>
            <img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소"/>
          </p>
        </div>
      </div>
    </H.kakaomapWrap>
  );
}
