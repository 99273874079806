//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
//아래 함수들에 대한 자세한 설명은 Axios.js 파일을 참고하세요
import {
  get_old_guarantee,
  get_old_guarantee_management_search,
} from "../../Axios";

export function OldGuaranteeManagement() {  

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [oldGuaranteeData, setOldGuaranteeData] = useState([]); //백엔드에서 가져온 보증기간 데이터를 담기위한 useState

  //검색 데이터
  const [oldGuaranteeSearch1, setOldGuaranteeSearch1] = useState('');
  const [oldGuaranteeSearch2, setOldGuaranteeSearch2] = useState('');

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

    // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
    useEffect(() => {
      //이거는 데이터베이스에 있는 보증기간들을 가져오기 위한 Function이고 
      //자세한 내용은 src/Axios.js 파일 확인
      get_old_guarantee().then(result_data=>{
        //보증기간 데이터를 setOldGuaranteeData를 활용하여 oldGuaranteeData에 할당
        setOldGuaranteeData(result_data);
        //만약에 보증기간 데이터를 어떻게 가져왔나를 확인하고 싶으면
        //아래 console.log(result_data)주석을 풀어서
        //크롬창에 F12누르면 콘솔에 찍힙니다.
        /* console.log(result_data); */
      })

    },[]);


  //컨트롤 함수
  const form_check = () =>{
    get_old_guarantee_management_search(
      oldGuaranteeSearch1,
      oldGuaranteeSearch2,
    ).then((result)=>{
      if(result.length === 0){
        alert('검색된 데이터가 없습니다. 시공점에 확인해주세요.');
      }else{
        setOldGuaranteeData(result);
      }
    });
  }
  
  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>(구)보증서 관리</p>
            <div>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_topSearch>
          <div className="admin_topSearchInner">
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='old_guarantee_search_1'>1차 검색명</label>
              <input 
                type='text' 
                id = 'old_guarantee_search_1' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOldGuaranteeSearch1(event_data.target.value);
                }} 
                placeholder = "1차 검색명을 입력해 주세요."
                value={oldGuaranteeSearch1}
              />
            </div>

            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='old_guarantee_search_2'>2차 검색명</label>
              <input 
                type='text' 
                id = 'old_guarantee_search_2' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOldGuaranteeSearch2(event_data.target.value);
                }} 
                placeholder = "2차 검색명을 입력해 주세요."
                value={oldGuaranteeSearch2}
              />
            </div>
          
            <button
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              onClick={()=>{form_check(); }}>
              {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
              검색
            </button>
          </div>
        </H.admin_topSearch>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            {
            oldGuaranteeData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={oldGuaranteeData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                { title: "ID", field: "old_guarantee_ID", headerSort:false},
                { title: "시리얼NO", field: "old_guarantee_serial_number", headerSort:false},
                { title: "고객명", field: "old_guarantee_customer_name", headerSort:false},
                { title: "고객전화번호", field: "old_guarantee_customer_phone_number", headerSort:false},
                { title: "필름제조사", field: "old_guarantee_manufacturing_company", headerSort:false},
                { title: "전면유리", field: "old_guarantee_windshield", headerSort:false},
                { title: "측면유리1", field: "old_guarantee_side_glass_1", headerSort:false},
                { title: "측면유리2", field: "old_guarantee_side_glass_2", headerSort:false},
                { title: "측면유리3", field: "old_guarantee_side_glass_3", headerSort:false},
                { title: "후면유리", field: "old_guarantee_rear_glass", headerSort:false},
                { title: "썬루프유리", field: "old_guarantee_sunroof_glass", headerSort:false},
                { title: "파노라마썬루프유리", field: "old_guarantee_panoramic_sunroof_glass", headerSort:false},
                { title: "기타부분시공", field: "old_guarantee_etc", headerSort:false},
                { title: "LOT NO", field: "old_guarantee_lot_number", headerSort:false},
                { title: "시공 일자", field: "old_guarantee_construction_date", headerSort:false},
                { title: "차종1", field: "old_guarantee_car_type_1", headerSort:false},
                { title: "차종2", field: "old_guarantee_car_type_2", headerSort:false},
                { title: "차종3", field: "old_guarantee_car_type_3", headerSort:false},
                { title: "차량번호", field: "old_guarantee_car_number", headerSort:false},
                { title: "메모", field: "old_guarantee_memo", headerSort:false},
                { title: "비고", field: "old_guarantee_note", headerSort:false},
              ]}
              options={{ 
                paginationSize:30,
                pagination:'local',
                paginationButtonCount:5, // 밑에 표시될 페이지 갯수
              }}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default OldGuaranteeManagement;
