//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_optimum_film_type,
  get_optimum_film_type_one,
  update_optimum_film_type,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function OptimumFilmTypeManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 OptimumFilmTypeManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/OptimumFilmTypeManagementAdd?optimum_film_type_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //optimum_film_type_ID=2를 쓸 수 있다.
  //즉 optimum_film_type_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const optimum_film_type_ID_param = params.get("optimum_film_type_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [optimumFilmTypeName, setOptimumFilmTypeName] = useState('');
  const [optimumFilmTypeDensity, setOptimumFilmTypeDensity] = useState('');
  const [optimumFilmTypeStatus, setOptimumFilmTypeStatus] = useState('1');

  //에러 출력 데이터
  const [optimumFilmTypeNameError, setOptimumFilmTypeNameError] = useState('');
  const [optimumFilmTypeDensityError, setOptimumFilmTypeDensityError] = useState('');


  //컨트롤 함수
  const optimum_film_type_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(optimumFilmTypeName === ''){setOptimumFilmTypeNameError('종류명을 등록해 주세요.'); continue_add = false;}else{setOptimumFilmTypeNameError('');}
    if(optimumFilmTypeDensity === ''){setOptimumFilmTypeDensityError('농도를 등록해 주세요.'); continue_add = false;}else{setOptimumFilmTypeDensityError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(optimum_film_type_ID_param === null){
        insert_optimum_film_type(
          optimumFilmTypeName,
          optimumFilmTypeDensity,
          optimumFilmTypeStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/OptimumFilmTypeManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_optimum_film_type(
          optimum_film_type_ID_param,
          optimumFilmTypeName,
          optimumFilmTypeDensity,
          optimumFilmTypeStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/OptimumFilmTypeManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 optimum_film_type_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(optimum_film_type_ID_param !== null){
      //특정 ID의 옵티멈 썬팅필름 종류 데이터를 가져오기 위한 함수
      get_optimum_film_type_one(optimum_film_type_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setOptimumFilmTypeName(result[0].optimum_film_type_name);
        setOptimumFilmTypeDensity(result[0].optimum_film_type_density);
        setOptimumFilmTypeStatus(result[0].optimum_film_type_status);
      })
    }
  },[optimum_film_type_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>보증서 관리 - 옵티멈 썬팅필름 종류 {optimum_film_type_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  optimum_film_type_check();
                }}
              >
                {/* 옵티멈 썬팅필름 종류 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {optimum_film_type_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_film_type_name'>종류명<span> * </span></label>
              <input 
                type='text' 
                id = 'optimum_film_type_name' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumFilmTypeName(event_data.target.value);
                }} 
                placeholder = "옵티멈 썬팅필름 종류명을 입력해 주세요."
                value={optimumFilmTypeName}
              />
              <H.admin_errorBox className="marginLeft150">{optimumFilmTypeNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_film_type_density'>농도<span> * </span></label>
              <input 
                type='text' 
                id = 'optimum_film_type_density' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumFilmTypeDensity(event_data.target.value);
                }} 
                placeholder = "옵티멈 썬팅필름 농도를 입력해 주세요."
                value={optimumFilmTypeDensity}
              />
              <H.admin_errorBox className="marginLeft150">{optimumFilmTypeDensityError}</H.admin_errorBox>
            </H.admin_labelBox>
            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}
            {optimum_film_type_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_film_type_status'>사용 여부</label>
              <select
                value={optimumFilmTypeStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumFilmTypeStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default OptimumFilmTypeManagementAdd;
