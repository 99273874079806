//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_guarantee_period,
  get_guarantee_period_one,
  update_guarantee_period,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function GuaranteePeriodManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 GuaranteePeriodManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/GuaranteePeriodManagementAdd?guarantee_period_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //guarantee_period_ID=2를 쓸 수 있다.
  //즉 guarantee_period_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const guarantee_period_ID_param = params.get("guarantee_period_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [guaranteePeriodYear, setGuaranteePeriodYear] = useState('');
  const [guaranteePeriodStatus, setGuaranteePeriodStatus] = useState('1');

  //에러 출력 데이터
  const [guaranteePeriodYearError, setGuaranteePeriodYearError] = useState('');

  //컨트롤 함수
  const guarantee_period_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(guaranteePeriodYear === ''){setGuaranteePeriodYearError('보증기간을 등록해 주세요.'); continue_add = false;}else{setGuaranteePeriodYearError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(guarantee_period_ID_param === null){
        insert_guarantee_period(
          guaranteePeriodYear,
          guaranteePeriodStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/GuaranteePeriodManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_guarantee_period(
          guarantee_period_ID_param,
          guaranteePeriodYear,
          guaranteePeriodStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/GuaranteePeriodManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 guarantee_period_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(guarantee_period_ID_param !== null){
      //특정 ID의 보증기간 데이터를 가져오기 위한 함수
      get_guarantee_period_one(guarantee_period_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setGuaranteePeriodYear(result[0].guarantee_period_year);
        setGuaranteePeriodStatus(result[0].guarantee_period_status);
      })
    }
  },[guarantee_period_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>보증서 관리 - 보증기간 {guarantee_period_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  guarantee_period_check();
                }}
              >
                {/* 보증기간 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {guarantee_period_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='guarantee_period_year'>보증기간명<span> * </span></label>
              <input 
                type='text' 
                id = 'guarantee_period_year' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setGuaranteePeriodYear(event_data.target.value);
                }} 
                placeholder = "보증기간명을 입력해 주세요."
                value={guaranteePeriodYear}
              />
              <H.admin_errorBox className="marginLeft150">{guaranteePeriodYearError}</H.admin_errorBox>
            </H.admin_labelBox>

            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}
            {guarantee_period_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='guarantee_period_status'>사용 여부</label>
              <select
                value={guaranteePeriodStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setGuaranteePeriodStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default GuaranteePeriodManagementAdd;
