//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState} from 'react';//Header 컴포넌트를 가져온 것이고
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import * as H from '../../Styles/StyleComponentHan';
import {
  base_url_export,
  get_optimum_tools_status_1,
} from "../../Axios";
//모바일인지 아닌지 판단하기 위한 패키지
import {isMobile} from 'react-device-detect';


function OptimumTool() {

  const [optimumToolsData, setOptimumToolsData] = useState([]); //백엔드에서 가져온 롤링 배너 데이터를 담기위한 useState

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 롤링 배너들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_optimum_tools_status_1().then(result_data=>{
      //롤링 배너 데이터를 setOptimumFilmBannerData 활용하여 optimumToolsData에 할당
      setOptimumToolsData(result_data);
      //만약에 롤링 배너 데이터를 어떻게 가져왔나를 확인하고 싶으면
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
    })
  },[]);

  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>
      <H.main_sectionBox >
        <div className="main_sectionBoxInner">
          <H.printMainTitBox>
            <h1 className="printMainTit">옵티멈 시공툴</h1>
          </H.printMainTitBox>

          <H.optimumToolBoxWrap>
            {optimumToolsData !== '' && optimumToolsData.map((element, index) => 
              <div className="optimumToolBox"
              key={index}
              >
                {/* onClick event에 대한 내용은 src/Admin/Login.js 참고*/}
                <a href={element.optimum_tools_link} target="_blank" rel="noreferrer">
                  {/* 여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서 export로 선언하여 사용하는 것이다. 
                  여기서 style-component를 사용한 이유는 반응형에 맞춰서 이미지를 PC로 할지 모바일로 할지에 대해서 판단하기 위해서
                  style-component를 사용했다.
                  src/Styles/StyleComponentRyu에 OptimumtoolsImg를 참조하면 쉽게 알 수 있을 것이다.
                  */}
                  <div className="optimumToolImg">
                    <img
                      src = {
                        isMobile ? 
                          base_url_export + element.optimum_tools_image_mobile : 
                          base_url_export + element.optimum_tools_image
                        }
                      alt={element.optimum_tools_name}
                    />
                  </div>
                  {element.optimum_tools_name !== '' &&
                  <p className="optimumToolTit">{element.optimum_tools_name}</p>}
                </a>
              </div>
            )}
          </H.optimumToolBoxWrap>

        </div>
      </H.main_sectionBox>

      <Footer/>
    </>
  );
}

export default OptimumTool;
