//여기는 프론트의 꽃인 API를 하기위한 Axios.js 파일입니다.
//import Axios from "axios"는 npm install axios를 통해서
//백엔드와 교류할 수 있는 axios 패키지를 설치한 것을 불러온 것 입니다.
//import, export에 대한 자세한 내용은 src/Admin/Login/Login.js에서 확인해주세요.
import Axios from "axios";

//테스트 서버는 자체서버를 활용하기 때문에 localhost이며 운영서버는 해당 주소를 입력한겁니다.
//작업할떄는 테스트서버를 주석을 풀고 운영서버를 주석처리하고 백엔드 키시고 작업하시면 됩니다.

const isProduction = process.env.NODE_ENV === 'production'

const base_url = isProduction
  ? 'https://www.optimumwindowfilm.com:6060/'
  : 'http://localhost:6060/'

export const base_url_export = isProduction
  ? 'https://www.optimumwindowfilm.com:6060/'
  : 'http://localhost:6060/'

//앞에 src/Admin/Login/Login.js에서 호출한 login_judgement에 대한 처리하는 Function입니다.
//login_judgement Function은 user_login_ID와, user_password의 매개변수로 입력받아
//data라는 FormData 변수를 만든 후 data.append를 하여 백엔드에 보낼 데이터를 집어 넣습니다.
//try{}catch{}는 try안의 내용들을 시도하다가 에러가 뜨면 catch로 이동해서 에러를 출력하게 됩니다.
//async awat는 비동기 통신이라고 하는데 이건 말로 설명하기가 너무 힘들어서 인터넷에 찾아보시면 됩니다.
//설치한 Axios 패키지를 활용하여
//Axios.post = Post 방법으로 백엔드랑 통신한다
//post는 암호화해서 보내는 방법이고 get은 주소창에 www.naver.com/ere?tab1=1&tab2=2
//이렇게 get은 ?뒤에 값들이 보내지는데
//백엔드에서 tab1은 1번이라고 인식해서 사용하고 tab2는 2번이라고 인식해서 사용하는건데
//왠만하면 post로 하는 것이 좋아요 :)
//`${base_url}login/login_judgement` 이부분은 백엔드랑 통신할 주소이며
//그밑에 data는 보낼 data
//headers, withCredentials는 인터넷 찾아보시면 됩니다.
//그리고 나서 return response.data는 받은 데이터를 return 시킨다
//즉return이라고 함은 rc/Admin/Login/Login.js에서 호출한 login_judgement에 
//백엔드에서 받은 값을 보내주는 겁니다.
//Axios는 url주소와 보내줄 데이터를 어떤걸 어디주소로 보낼테니 어떤거를 반환해달라고
//백엔드 개발자와 협조해야 되는 부분입니다. :)
export const login_judgement = async (user_login_ID, user_password) => {
  let data = new FormData();

  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);

  try{
      const response = await Axios.post(
          `${base_url}login/login_judgement`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//홈페이지 시공점 로그인 판단 API
export const login_judgement_construction_point = async (
  construction_point_login_ID, 
  construction_point_password
) => {
  let data = new FormData();

  data.append('construction_point_login_ID',construction_point_login_ID);
  data.append('construction_point_password',construction_point_password);

  try{
      const response = await Axios.post(
          `${base_url}login/login_judgement_construction_point`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//이거는 사용자가 로그인을 할때 백엔드 서버에 세션이라는거를 저장하게 되는데 
//세션이 없으면 당연히 로그인을 안한거고 로그인을 안했으면
//관리자페이지를 사용하지 못하게 만들어야 하기 때문에
//백엔드 서버에 세션이 있는지 없는지 판단해달라고 보내는 요청입니다.
export const get_session_data_admin = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/get_session_data_admin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//이거는 사용자가 로그인을 할때 백엔드 서버에 세션이라는거를 저장하게 되는데 
//세션이 없으면 당연히 로그인을 안한거고 로그인을 안했으면
//홈페이지에서 로그인 기능을 사용하지 못하게 만들어야 하기 때문에
//백엔드 서버에 세션이 있는지 없는지 판단해달라고 보내는 요청입니다.
export const get_session_data_homepage = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/get_session_data_homepage`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 서버에 가지고 있는 세션을 파괴하는 겁니다.
export const delete_session_data_homepage = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/delete_session_data_homepage`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 롤링 배너를 가져오는 API입니다.
export const get_rolling_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 롤링 배너를 가져오는 API입니다.
export const get_rolling_banner_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너 순서를 업데이트 시키는 API입니다.
export const update_rolling_banner_order = async (
  rolling_banner_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('rolling_banner_order_array',JSON.stringify(rolling_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 등록하는 API입니다.
export const insert_rolling_banner = async (
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
) => {

  let data = new FormData();
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 수정하는 API입니다.
export const update_rolling_banner = async (
  rolling_banner_ID,
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
  rolling_banner_status,
) => {

  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);
  data.append('rolling_banner_status',rolling_banner_status);

  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 삭제하는 API입니다.
export const delete_rolling_banner = async (
  rolling_banner_ID_array,
) => {

  let data = new FormData();
  data.append('rolling_banner_ID_array',rolling_banner_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 롤링 배너를 가져오기 위한 API입니다.
export const get_rolling_banner_one = async (
  rolling_banner_ID
) => {
  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 소형 이미지 배너를 가져오는 API입니다.
export const get_small_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//소형 이미지 배너 순서를 업데이트 시키는 API입니다.
export const update_small_banner_order = async (
  small_banner_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('small_banner_order_array',JSON.stringify(small_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//소형 이미지 배너를 등록하는 API입니다.
export const insert_small_banner = async (
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {

  let data = new FormData();
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//소형 이미지 배너를 수정하는 API입니다.
export const update_small_banner = async (
  small_banner_ID,
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {

  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//소형 이미지 배너를 삭제하는 API입니다.
export const delete_small_banner = async (
  small_banner_ID_array,
) => {

  let data = new FormData();
  data.append('small_banner_ID_array',small_banner_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 소형 이미지 배너를 가져오기 위한 API입니다.
export const get_small_banner_one = async (
  small_banner_ID
) => {
  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//에디터에 대한 이미지를 업로드 합니다.
export const insert_editor_image = async (
  editor_file
) => {
  let data = new FormData();
  data.append('editor_file',editor_file.blob());

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/insert_editor_image`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
  
}

//에디터 내용을 가져오는 API입니다.
export const get_editor_data = async (
  editor_division
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//에디터 내용을 수정하는 API입니다.
export const update_editor_data = async (
  editor_division,
  editor_content,
  editor_content_mobile,
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);
  data.append('editor_content',editor_content);
  data.append('editor_content_mobile',editor_content_mobile);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 시공점을 가져오는 API입니다.
export const get_construction_point = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_construction_point`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용 여부를 사용이라고 했던 시공점을 가져오는 API입니다.
export const get_construction_point_use = async (
  construction_point_big_area,
  construction_point_search_input,
  construction_point_division
) => {
  let data = new FormData();
  data.append('construction_point_big_area',construction_point_big_area);
  data.append('construction_point_search_input',construction_point_search_input);
  data.append('construction_point_division',construction_point_division);
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_construction_point_use`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 시공점을 가져오기 위한 API입니다.
export const get_construction_point_one = async (
  construction_point_ID
) => {
  let data = new FormData();
  data.append('construction_point_ID',construction_point_ID);
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_construction_point_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 시공점 지역데이터를 가져오는 API입니다.
export const get_construction_point_big_area = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_construction_point_big_area`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//시공점 순서를 업데이트 시키는 API입니다.
export const update_construction_point_order = async (
  construction_point_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('construction_point_order_array',JSON.stringify(construction_point_order_array));
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_construction_point_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//시공점을 등록하는 API입니다.
export const insert_construction_point = async (
  construction_point_name,
  construction_point_representative,
  construction_point_phone_number,
  construction_point_zip_code,
  construction_point_road_address,
  construction_point_detail_address,
  construction_point_big_area,
  construction_point_small_area,
  construction_point_kakao_url,
  construction_point_status,
  construction_point_x,
  construction_point_y,
  construction_point_division,
  construction_point_login_ID,
  construction_point_password,
) => {

  let data = new FormData();
  data.append('construction_point_name',construction_point_name);
  data.append('construction_point_representative',construction_point_representative);
  data.append('construction_point_phone_number',construction_point_phone_number);
  data.append('construction_point_zip_code',construction_point_zip_code);
  data.append('construction_point_road_address',construction_point_road_address);
  data.append('construction_point_detail_address',construction_point_detail_address);
  data.append('construction_point_big_area',construction_point_big_area);
  data.append('construction_point_small_area',construction_point_small_area);
  data.append('construction_point_kakao_url',construction_point_kakao_url);
  data.append('construction_point_status',construction_point_status);
  data.append('construction_point_x',construction_point_x);
  data.append('construction_point_y',construction_point_y);
  data.append('construction_point_division',construction_point_division);
  data.append('construction_point_login_ID',construction_point_login_ID);
  data.append('construction_point_password',construction_point_password);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/insert_construction_point`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//시공점을 수정하는 API입니다.
export const update_construction_point = async (
  construction_point_ID,
  construction_point_name,
  construction_point_representative,
  construction_point_phone_number,
  construction_point_zip_code,
  construction_point_road_address,
  construction_point_detail_address,
  construction_point_big_area,
  construction_point_small_area,
  construction_point_kakao_url,
  construction_point_status,
  construction_point_x,
  construction_point_y,
  construction_point_division,
  construction_point_login_ID,
  construction_point_password,
) => {

  let data = new FormData();
  data.append('construction_point_ID',construction_point_ID);
  data.append('construction_point_name',construction_point_name);
  data.append('construction_point_representative',construction_point_representative);
  data.append('construction_point_phone_number',construction_point_phone_number);
  data.append('construction_point_zip_code',construction_point_zip_code);
  data.append('construction_point_road_address',construction_point_road_address);
  data.append('construction_point_detail_address',construction_point_detail_address);
  data.append('construction_point_big_area',construction_point_big_area);
  data.append('construction_point_small_area',construction_point_small_area);
  data.append('construction_point_kakao_url',construction_point_kakao_url);
  data.append('construction_point_status',construction_point_status);
  data.append('construction_point_x',construction_point_x);
  data.append('construction_point_y',construction_point_y);
  data.append('construction_point_division',construction_point_division);
  data.append('construction_point_login_ID',construction_point_login_ID);
  data.append('construction_point_password',construction_point_password);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_construction_point`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//도로명주소에 대한 x,y좌표값을 가져오기 위한 Axios입니다.
//이거는 카카오에서 제공하는 API입니다.
export const get_x_y_coordinate = async (
  road_address
) => {
  try{
      const response = await Axios.get(
          'https://dapi.kakao.com/v2/local/search/address.json?query='+road_address,
          {
              headers: {
                Authorization : 'KakaoAK f8664e58dec4ffacc3089853a65bf84d'
              },
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 뉴스 & 이벤트를 가져오는 API입니다.
export const get_news_event = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 뉴스 & 이벤트 중 사용가능으로 설정한 데이터를 가져오는 API입니다.
export const get_news_event_status = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_news_event_status`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트 순서를 업데이트 시키는 API입니다.
export const update_news_event_order = async (
  news_event_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('news_event_order_array',JSON.stringify(news_event_order_array));
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_news_event_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 등록하는 API입니다.
export const insert_news_event = async (
  news_event_title,
  news_event_sub_title,
  news_event_main_image,
  news_event_editor,
  news_event_editor_mobile,
) => {

  let data = new FormData();
  data.append('news_event_title',news_event_title);
  data.append('news_event_sub_title',news_event_sub_title);
  data.append('news_event_main_image',news_event_main_image);
  data.append('news_event_editor',news_event_editor);
  data.append('news_event_editor_mobile',news_event_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/insert_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 수정하는 API입니다.
export const update_news_event = async (
  news_event_ID,
  news_event_title,
  news_event_sub_title,
  news_event_main_image,
  news_event_status,
  news_event_editor,
  news_event_editor_mobile,
) => {

  let data = new FormData();
  data.append('news_event_ID',news_event_ID);
  data.append('news_event_title',news_event_title);
  data.append('news_event_sub_title',news_event_sub_title);
  data.append('news_event_main_image',news_event_main_image);
  data.append('news_event_status',news_event_status);
  data.append('news_event_editor',news_event_editor);
  data.append('news_event_editor_mobile',news_event_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 삭제하는 API입니다.
export const delete_news_event = async (
  news_event_ID_array,

) => {

  let data = new FormData();
  data.append('news_event_ID_array',news_event_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/delete_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 뉴스 & 이벤트를 가져오기 위한 API입니다.
export const get_news_event_one = async (
  news_event_ID
) => {
  let data = new FormData();
  data.append('news_event_ID',news_event_ID);
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_news_event_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 옵터멈필름배너를 가져오는 API입니다.
export const get_optimum_film_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름배너 순서를 업데이트 시키는 API입니다.
export const update_optimum_film_banner_order = async (
  optimum_film_banner_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('optimum_film_banner_order_array',JSON.stringify(optimum_film_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_film_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름배너를 등록하는 API입니다.
export const insert_optimum_film_banner = async (
  optimum_film_banner_name,
  optimum_film_banner_image,
  optimum_film_banner_link,
  optimum_film_banner_editor,
  optimum_film_banner_editor_mobile
) => {

  let data = new FormData();
  data.append('optimum_film_banner_name',optimum_film_banner_name);
  data.append('optimum_film_banner_image',optimum_film_banner_image);
  data.append('optimum_film_banner_link',optimum_film_banner_link);
  data.append('optimum_film_banner_editor',optimum_film_banner_editor);
  data.append('optimum_film_banner_editor_mobile',optimum_film_banner_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_optimum_film_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름배너를 수정하는 API입니다.
export const update_optimum_film_banner = async (
  optimum_film_banner_ID,
  optimum_film_banner_name,
  optimum_film_banner_image,
  optimum_film_banner_link,
  optimum_film_banner_editor,
  optimum_film_banner_editor_mobile
) => {

  let data = new FormData();
  data.append('optimum_film_banner_ID',optimum_film_banner_ID);
  data.append('optimum_film_banner_name',optimum_film_banner_name);
  data.append('optimum_film_banner_image',optimum_film_banner_image);
  data.append('optimum_film_banner_link',optimum_film_banner_link);
  data.append('optimum_film_banner_editor',optimum_film_banner_editor);
  data.append('optimum_film_banner_editor_mobile',optimum_film_banner_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_film_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름배너를 삭제하는 API입니다.
export const delete_optimum_film_banner = async (
  optimum_film_banner_ID_array,
) => {

  let data = new FormData();
  data.append('optimum_film_banner_ID_array',optimum_film_banner_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_optimum_film_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 옵터멈필름배너를 가져오기 위한 API입니다.
export const get_optimum_film_banner_one = async (
  optimum_film_banner_ID
) => {
  let data = new FormData();
  data.append('optimum_film_banner_ID',optimum_film_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 옵터멈필름종류를 가져오는 API입니다.
export const get_optimum_film_type = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_type`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 옵터멈필름종류를 가져오는 API입니다.
export const get_optimum_film_type_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_type_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}


//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 옵터멈필름종류명을 가져오는 API입니다.
export const get_optimum_film_type_name_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_type_name_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름종류 순서를 업데이트 시키는 API입니다.
export const update_optimum_film_type_order = async (
  optimum_film_type_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('optimum_film_type_order_array',JSON.stringify(optimum_film_type_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_film_type_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름종류를 등록하는 API입니다.
export const insert_optimum_film_type = async (
  optimum_film_type_name,
  optimum_film_type_density,
  optimum_film_type_status,
) => {

  let data = new FormData();
  data.append('optimum_film_type_name',optimum_film_type_name);
  data.append('optimum_film_type_density',optimum_film_type_density);
  data.append('optimum_film_type_status',optimum_film_type_status);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_optimum_film_type`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름종류를 수정하는 API입니다.
export const update_optimum_film_type = async (
  optimum_film_type_ID,
  optimum_film_type_name,
  optimum_film_type_density,
  optimum_film_type_status,
) => {

  let data = new FormData();
  data.append('optimum_film_type_ID',optimum_film_type_ID);
  data.append('optimum_film_type_name',optimum_film_type_name);
  data.append('optimum_film_type_density',optimum_film_type_density);
  data.append('optimum_film_type_status',optimum_film_type_status);

  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_film_type`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵터멈필름종류를 삭제하는 API입니다.
export const delete_optimum_film_type = async (
  optimum_film_type_ID_array,
) => {

  let data = new FormData();
  data.append('optimum_film_type_ID_array',optimum_film_type_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_optimum_film_type`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 옵터멈필름종류를 가져오기 위한 API입니다.
export const get_optimum_film_type_one = async (
  optimum_film_type_ID
) => {
  let data = new FormData();
  data.append('optimum_film_type_ID',optimum_film_type_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_film_type_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 보증기간를 가져오는 API입니다.
export const get_guarantee_period = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_guarantee_period`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 보증기간을 가져오는 API입니다.
export const get_guarantee_period_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_guarantee_period_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//보증기간 순서를 업데이트 시키는 API입니다.
export const update_guarantee_period_order = async (
  guarantee_period_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('guarantee_period_order_array',JSON.stringify(guarantee_period_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_guarantee_period_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//보증기간를 등록하는 API입니다.
export const insert_guarantee_period = async (
  guarantee_period_year,
  guarantee_period_status,
) => {

  let data = new FormData();
  data.append('guarantee_period_year',guarantee_period_year);
  data.append('guarantee_period_status',guarantee_period_status);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_guarantee_period`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//보증기간를 수정하는 API입니다.
export const update_guarantee_period = async (
  guarantee_period_ID,
  guarantee_period_year,
  guarantee_period_status,
) => {

  let data = new FormData();
  data.append('guarantee_period_ID',guarantee_period_ID);
  data.append('guarantee_period_year',guarantee_period_year);
  data.append('guarantee_period_status',guarantee_period_status);

  try{
      const response = await Axios.post(
          `${base_url}main/update_guarantee_period`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//보증기간를 삭제하는 API입니다.
export const delete_guarantee_period = async (
  guarantee_period_ID_array,
) => {

  let data = new FormData();
  data.append('guarantee_period_ID_array',guarantee_period_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_guarantee_period`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 보증기간를 가져오기 위한 API입니다.
export const get_guarantee_period_one = async (
  guarantee_period_ID
) => {
  let data = new FormData();
  data.append('guarantee_period_ID',guarantee_period_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_guarantee_period_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}


//백엔드 데이터베이스에 저장되어 있는 (구)보증서데이터를 가져오는 API입니다.
export const get_old_guarantee = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_old_guarantee`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 해당 지점의 (구)보증서데이터를 가져오는 API입니다.
export const get_old_guarantee_management_search = async (
  old_guarantee_search_1,
  old_guarantee_search_2,
) => {
  
  let data = new FormData();
  data.append('old_guarantee_search_1',old_guarantee_search_1);
  data.append('old_guarantee_search_2',old_guarantee_search_2);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_old_guarantee_management_search`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 보증서데이터를 가져오는 API입니다.
export const get_guarantee = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_guarantee`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 해당 지점의 보증서데이터를 가져오는 API입니다.
export const get_guarantee_construction_point_ID = async (
  construction_point_ID_use_guarantee,
  guarantee_search_name,
) => {
  
  let data = new FormData();
  data.append('construction_point_ID_use_guarantee',construction_point_ID_use_guarantee);
  data.append('guarantee_search_name',guarantee_search_name);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_guarantee_construction_point_ID`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 해당 지점의 보증서데이터를 가져오는 API입니다.
export const get_guarantee_customer = async (
  guarantee_customer_name,
  guarantee_customer_phone_number,
) => {
  let data = new FormData();
  data.append('guarantee_customer_name',guarantee_customer_name);
  data.append('guarantee_customer_phone_number',guarantee_customer_phone_number);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_guarantee_customer`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 해당 지점의 보증서데이터를 가져오는 API입니다.
export const get_guarantee_management_search = async (
  guarantee_search_1,
  guarantee_search_2,
) => {
  
  let data = new FormData();
  data.append('guarantee_search_1',guarantee_search_1);
  data.append('guarantee_search_2',guarantee_search_2);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_guarantee_management_search`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_guarantee = async (
  construction_point_ID_use_guarantee,
  guarantee_vehicle_division,
  guarantee_windshield_use_optimum_film_type_ID,
  guarantee_windshield_lot_number,
  guarantee_windshield_use_guarantee_period_ID,
  guarantee_side_glass_1_use_optimum_film_type_ID,
  guarantee_side_glass_1_lot_number,
  guarantee_side_glass_1_use_guarantee_period_ID,
  guarantee_side_glass_2_use_optimum_film_type_ID,
  guarantee_side_glass_2_lot_number,
  guarantee_side_glass_2_use_guarantee_period_ID,
  guarantee_side_glass_3_use_optimum_film_type_ID,
  guarantee_side_glass_3_lot_number,
  guarantee_side_glass_3_use_guarantee_period_ID,
  guarantee_rear_glass_use_optimum_film_type_ID,
  guarantee_rear_glass_lot_number,
  guarantee_rear_glass_use_guarantee_period_ID,
  guarantee_roof_glass_use_optimum_film_type_ID,
  guarantee_roof_glass_lot_number,
  guarantee_roof_glass_use_guarantee_period_ID,
  guarantee_construction_date,
  guarantee_car_type,
  guarantee_car_number,
  guarantee_customer_name,
  guarantee_customer_phone_number,
  guarantee_review,
  guarantee_memo,
  guiarantee_construction_location,
  guiarantee_construction_film
) => {

  let data = new FormData();
  data.append('construction_point_ID_use_guarantee',construction_point_ID_use_guarantee);
  data.append('guarantee_vehicle_division',guarantee_vehicle_division);
  data.append('guarantee_windshield_use_optimum_film_type_ID',guarantee_windshield_use_optimum_film_type_ID);
  data.append('guarantee_windshield_lot_number',guarantee_windshield_lot_number);
  data.append('guarantee_windshield_use_guarantee_period_ID',guarantee_windshield_use_guarantee_period_ID);
  data.append('guarantee_side_glass_1_use_optimum_film_type_ID',guarantee_side_glass_1_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_1_lot_number',guarantee_side_glass_1_lot_number);
  data.append('guarantee_side_glass_1_use_guarantee_period_ID',guarantee_side_glass_1_use_guarantee_period_ID);
  data.append('guarantee_side_glass_2_use_optimum_film_type_ID',guarantee_side_glass_2_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_2_lot_number',guarantee_side_glass_2_lot_number);
  data.append('guarantee_side_glass_2_use_guarantee_period_ID',guarantee_side_glass_2_use_guarantee_period_ID);
  data.append('guarantee_side_glass_3_use_optimum_film_type_ID',guarantee_side_glass_3_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_3_lot_number',guarantee_side_glass_3_lot_number);
  data.append('guarantee_side_glass_3_use_guarantee_period_ID',guarantee_side_glass_3_use_guarantee_period_ID);
  data.append('guarantee_rear_glass_use_optimum_film_type_ID',guarantee_rear_glass_use_optimum_film_type_ID);
  data.append('guarantee_rear_glass_lot_number',guarantee_rear_glass_lot_number);
  data.append('guarantee_rear_glass_use_guarantee_period_ID',guarantee_rear_glass_use_guarantee_period_ID);
  data.append('guarantee_roof_glass_use_optimum_film_type_ID',guarantee_roof_glass_use_optimum_film_type_ID);
  data.append('guarantee_roof_glass_lot_number',guarantee_roof_glass_lot_number);
  data.append('guarantee_roof_glass_use_guarantee_period_ID',guarantee_roof_glass_use_guarantee_period_ID);
  data.append('guarantee_construction_date',guarantee_construction_date);
  data.append('guarantee_car_type',guarantee_car_type);
  data.append('guarantee_car_number',guarantee_car_number);
  data.append('guarantee_customer_name',guarantee_customer_name);
  data.append('guarantee_customer_phone_number',guarantee_customer_phone_number);
  data.append('guarantee_review',guarantee_review);
  data.append('guarantee_memo',guarantee_memo);
  data.append('guiarantee_construction_location',guiarantee_construction_location);
  data.append('guiarantee_construction_film',guiarantee_construction_film);


  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/insert_guarantee`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_guarantee = async (
  guarantee_ID,
  construction_point_ID_use_guarantee,
  guarantee_vehicle_division,
  guarantee_windshield_use_optimum_film_type_ID,
  guarantee_windshield_lot_number,
  guarantee_windshield_use_guarantee_period_ID,
  guarantee_side_glass_1_use_optimum_film_type_ID,
  guarantee_side_glass_1_lot_number,
  guarantee_side_glass_1_use_guarantee_period_ID,
  guarantee_side_glass_2_use_optimum_film_type_ID,
  guarantee_side_glass_2_lot_number,
  guarantee_side_glass_2_use_guarantee_period_ID,
  guarantee_side_glass_3_use_optimum_film_type_ID,
  guarantee_side_glass_3_lot_number,
  guarantee_side_glass_3_use_guarantee_period_ID,
  guarantee_rear_glass_use_optimum_film_type_ID,
  guarantee_rear_glass_lot_number,
  guarantee_rear_glass_use_guarantee_period_ID,
  guarantee_roof_glass_use_optimum_film_type_ID,
  guarantee_roof_glass_lot_number,
  guarantee_roof_glass_use_guarantee_period_ID,
  guarantee_construction_date,
  guarantee_car_type,
  guarantee_car_number,
  guarantee_customer_name,
  guarantee_customer_phone_number,
  guarantee_review,
  guarantee_memo,
  guiarantee_construction_location,
  guiarantee_construction_film
) => {

  let data = new FormData();
  data.append('guarantee_ID',guarantee_ID);
  data.append('construction_point_ID_use_guarantee',construction_point_ID_use_guarantee);
  data.append('guarantee_vehicle_division',guarantee_vehicle_division);
  data.append('guarantee_windshield_use_optimum_film_type_ID',guarantee_windshield_use_optimum_film_type_ID);
  data.append('guarantee_windshield_lot_number',guarantee_windshield_lot_number);
  data.append('guarantee_windshield_use_guarantee_period_ID',guarantee_windshield_use_guarantee_period_ID);
  data.append('guarantee_side_glass_1_use_optimum_film_type_ID',guarantee_side_glass_1_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_1_lot_number',guarantee_side_glass_1_lot_number);
  data.append('guarantee_side_glass_1_use_guarantee_period_ID',guarantee_side_glass_1_use_guarantee_period_ID);
  data.append('guarantee_side_glass_2_use_optimum_film_type_ID',guarantee_side_glass_2_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_2_lot_number',guarantee_side_glass_2_lot_number);
  data.append('guarantee_side_glass_2_use_guarantee_period_ID',guarantee_side_glass_2_use_guarantee_period_ID);
  data.append('guarantee_side_glass_3_use_optimum_film_type_ID',guarantee_side_glass_3_use_optimum_film_type_ID);
  data.append('guarantee_side_glass_3_lot_number',guarantee_side_glass_3_lot_number);
  data.append('guarantee_side_glass_3_use_guarantee_period_ID',guarantee_side_glass_3_use_guarantee_period_ID);
  data.append('guarantee_rear_glass_use_optimum_film_type_ID',guarantee_rear_glass_use_optimum_film_type_ID);
  data.append('guarantee_rear_glass_lot_number',guarantee_rear_glass_lot_number);
  data.append('guarantee_rear_glass_use_guarantee_period_ID',guarantee_rear_glass_use_guarantee_period_ID);
  data.append('guarantee_roof_glass_use_optimum_film_type_ID',guarantee_roof_glass_use_optimum_film_type_ID);
  data.append('guarantee_roof_glass_lot_number',guarantee_roof_glass_lot_number);
  data.append('guarantee_roof_glass_use_guarantee_period_ID',guarantee_roof_glass_use_guarantee_period_ID);
  data.append('guarantee_construction_date',guarantee_construction_date);
  data.append('guarantee_car_type',guarantee_car_type);
  data.append('guarantee_car_number',guarantee_car_number);
  data.append('guarantee_customer_name',guarantee_customer_name);
  data.append('guarantee_customer_phone_number',guarantee_customer_phone_number);
  data.append('guarantee_review',guarantee_review);
  data.append('guarantee_memo',guarantee_memo);
  data.append('guiarantee_construction_location',guiarantee_construction_location);
  data.append('guiarantee_construction_film',guiarantee_construction_film);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_guarantee`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 보증서데이터를 가져오는 API입니다.
export const get_guarantee_one = async (
  guarantee_ID
) => {
  let data = new FormData();
  data.append('guarantee_ID',guarantee_ID);
  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_guarantee_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//고정배너 내용을 가져오는 API입니다.
export const get_fix_banner = async (
  fix_banner_ID
) => {
  let data = new FormData();
  data.append('fix_banner_ID',fix_banner_ID);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/get_fix_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}


//고정배너 내용을 가져오는 API입니다.
export const update_fix_banner = async (
  fix_banner_ID,
  fix_banner_image,
  fix_banner_image_mobile,
  fix_banner_link,
  fix_banner_title,
) => {

  let data = new FormData();
  data.append('fix_banner_ID',fix_banner_ID);
  data.append('fix_banner_image',fix_banner_image);
  data.append('fix_banner_image_mobile',fix_banner_image_mobile);
  data.append('fix_banner_link',fix_banner_link);
  data.append('fix_banner_title',fix_banner_title);

  try{
      const response = await Axios.post(
          `${base_url}optimum_window_film/update_fix_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 메뉴권한을 가져오는 API입니다.
export const get_menu_permission = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 메뉴권한를 가져오기 위한 API입니다.
export const get_menu_permission_one = async (
  menu_permission_ID
) => {
  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_menu_permission_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 메뉴권한를 사용으로 
//지정된 메뉴권한을 가져오는 API입니다.
export const get_menu_permission_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_menu_permission_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//메뉴권한를 등록하는 API입니다.
export const insert_menu_permission = async (
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {

  let data = new FormData();
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//메뉴권한를 수정하는 API입니다.
export const update_menu_permission = async (
  menu_permission_ID,
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {

  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);

  try{
      const response = await Axios.post(
          `${base_url}main/update_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용자를 가져오는 API입니다.
export const get_user = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 사용자를 가져오기 위한 API입니다.
export const get_user_one = async (
  user_ID
) => {
  let data = new FormData();
  data.append('user_ID',user_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_user_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용자를 등록하는 API입니다.
export const insert_user = async (
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {

  let data = new FormData();
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용자를 수정하는 API입니다.
export const update_user = async (
  user_ID,
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {

  let data = new FormData();
  data.append('user_ID',user_ID);
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);


  try{
      const response = await Axios.post(
          `${base_url}main/update_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 옵티멈 툴를 가져오는 API입니다.
export const get_optimum_tools = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_tools`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 옵티멈 툴를 가져오는 API입니다.
export const get_optimum_tools_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_tools_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵티멈 툴 순서를 업데이트 시키는 API입니다.
export const update_optimum_tools_order = async (
  optimum_tools_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('optimum_tools_order_array',JSON.stringify(optimum_tools_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_tools_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵티멈 툴를 등록하는 API입니다.
export const insert_optimum_tools = async (
  optimum_tools_name,
  optimum_tools_image,
  optimum_tools_image_mobile,
  optimum_tools_link,
  optimum_tools_status,
) => {

  let data = new FormData();
  data.append('optimum_tools_name',optimum_tools_name);
  data.append('optimum_tools_image',optimum_tools_image);
  data.append('optimum_tools_image_mobile',optimum_tools_image_mobile);
  data.append('optimum_tools_link',optimum_tools_link);
  data.append('optimum_tools_status',optimum_tools_status);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_optimum_tools`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵티멈 툴를 수정하는 API입니다.
export const update_optimum_tools = async (
  optimum_tools_ID,
  optimum_tools_name,
  optimum_tools_image,
  optimum_tools_image_mobile,
  optimum_tools_link,
  optimum_tools_status,
) => {

  let data = new FormData();
  data.append('optimum_tools_ID',optimum_tools_ID);
  data.append('optimum_tools_name',optimum_tools_name);
  data.append('optimum_tools_image',optimum_tools_image);
  data.append('optimum_tools_image_mobile',optimum_tools_image_mobile);
  data.append('optimum_tools_link',optimum_tools_link);
  data.append('optimum_tools_status',optimum_tools_status);

  try{
      const response = await Axios.post(
          `${base_url}main/update_optimum_tools`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//옵티멈 툴를 삭제하는 API입니다.
export const delete_optimum_tools = async (
  optimum_tools_ID_array,
) => {

  let data = new FormData();
  data.append('optimum_tools_ID_array',optimum_tools_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_optimum_tools`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 옵티멈 툴를 가져오기 위한 API입니다.
export const get_optimum_tools_one = async (
  optimum_tools_ID
) => {
  let data = new FormData();
  data.append('optimum_tools_ID',optimum_tools_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_optimum_tools_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 공지사항을 가져오는 API입니다.
export const get_notice = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_notice`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//공지사항 순서를 업데이트 시키는 API입니다.
export const update_notice_order = async (
  notice_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('notice_order_array',JSON.stringify(notice_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_notice_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//공지사항을 등록하는 API입니다.
export const insert_notice = async (
  notice_title,
  notice_file_1,
  notice_file_2,
  notice_file_3,
  notice_file_4,
  notice_file_5,
  notice_file_text_1,
  notice_file_text_2,
  notice_file_text_3,
  notice_file_text_4,
  notice_file_text_5,
  notice_content
) => {

  let data = new FormData();
  data.append('notice_title',notice_title);
  data.append('notice_file_1',notice_file_1);
  data.append('notice_file_2',notice_file_2);
  data.append('notice_file_3',notice_file_3);
  data.append('notice_file_4',notice_file_4);
  data.append('notice_file_5',notice_file_5);
  data.append('notice_file_text_1',notice_file_text_1);
  data.append('notice_file_text_2',notice_file_text_2);
  data.append('notice_file_text_3',notice_file_text_3);
  data.append('notice_file_text_4',notice_file_text_4);
  data.append('notice_file_text_5',notice_file_text_5);
  data.append('notice_content',notice_content);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_notice`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//공지사항을 수정하는 API입니다.
export const update_notice = async (
  notice_ID,
  notice_title,
  notice_file_1,
  notice_file_2,
  notice_file_3,
  notice_file_4,
  notice_file_5,
  notice_file_text_1,
  notice_file_text_2,
  notice_file_text_3,
  notice_file_text_4,
  notice_file_text_5,
  notice_status,
  notice_content
) => {

  let data = new FormData();
  data.append('notice_ID',notice_ID);
  data.append('notice_title',notice_title);
  data.append('notice_file_1',notice_file_1);
  data.append('notice_file_2',notice_file_2);
  data.append('notice_file_3',notice_file_3);
  data.append('notice_file_4',notice_file_4);
  data.append('notice_file_5',notice_file_5);
  data.append('notice_file_text_1',notice_file_text_1);
  data.append('notice_file_text_2',notice_file_text_2);
  data.append('notice_file_text_3',notice_file_text_3);
  data.append('notice_file_text_4',notice_file_text_4);
  data.append('notice_file_text_5',notice_file_text_5);
  data.append('notice_status',notice_status);
  data.append('notice_content',notice_content);


  try{
      const response = await Axios.post(
          `${base_url}main/update_notice`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//공지사항을 삭제하는 API입니다.
export const delete_notice = async (
  notice_ID_array,
) => {

  let data = new FormData();
  data.append('notice_ID_array',notice_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_notice`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 공지사항을 가져오기 위한 API입니다.
export const get_notice_one = async (
  notice_ID
) => {
  let data = new FormData();
  data.append('notice_ID',notice_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_notice_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용중인 공지사항을 가져오기 위한 API입니다.
export const get_notice_use = async (
) => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_notice_use`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 자료실를 가져오는 API입니다.
export const get_archive = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_archive`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}


//특정 ID의 자료실를 가져오기 위한 API입니다.
export const get_archive_one = async (
  archive_ID
) => {
  let data = new FormData();
  data.append('archive_ID',archive_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_archive_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용중인 자료실을 가져오기 위한 API입니다.
export const get_archive_use = async (
  ) => {
    let data = new FormData();
    try{
        const response = await Axios.post(
            `${base_url}main/get_archive_use`,
            data,
            {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                withCredentials: true
            },
        );
        return response.data;
    }catch(err){
        console.log(err);
    }
  }

//자료실 순서를 업데이트 시키는 API입니다.
export const update_archive_order = async (
  archive_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('archive_order_array',JSON.stringify(archive_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_archive_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//자료실를 등록하는 API입니다.
export const insert_archive = async (
  archive_title,
  archive_file_1,
  archive_file_2,
  archive_file_3,
  archive_file_4,
  archive_file_5,
  archive_file_text_1,
  archive_file_text_2,
  archive_file_text_3,
  archive_file_text_4,
  archive_file_text_5,
  archive_content
) => {

  let data = new FormData();
  data.append('archive_title',archive_title);
  data.append('archive_file_1',archive_file_1);
  data.append('archive_file_2',archive_file_2);
  data.append('archive_file_3',archive_file_3);
  data.append('archive_file_4',archive_file_4);
  data.append('archive_file_5',archive_file_5);
  data.append('archive_file_text_1',archive_file_text_1);
  data.append('archive_file_text_2',archive_file_text_2);
  data.append('archive_file_text_3',archive_file_text_3);
  data.append('archive_file_text_4',archive_file_text_4);
  data.append('archive_file_text_5',archive_file_text_5);
  data.append('archive_content',archive_content);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_archive`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//자료실를 수정하는 API입니다.
export const update_archive = async (
  archive_ID,
  archive_title,
  archive_file_1,
  archive_file_2,
  archive_file_3,
  archive_file_4,
  archive_file_5,
  archive_file_text_1,
  archive_file_text_2,
  archive_file_text_3,
  archive_file_text_4,
  archive_file_text_5,
  archive_status,
  archive_content,
) => {

  let data = new FormData();
  data.append('archive_ID',archive_ID);
  data.append('archive_title',archive_title);
  data.append('archive_file_1',archive_file_1);
  data.append('archive_file_2',archive_file_2);
  data.append('archive_file_3',archive_file_3);
  data.append('archive_file_4',archive_file_4);
  data.append('archive_file_5',archive_file_5);
  data.append('archive_file_text_1',archive_file_text_1);
  data.append('archive_file_text_2',archive_file_text_2);
  data.append('archive_file_text_3',archive_file_text_3);
  data.append('archive_file_text_4',archive_file_text_4);
  data.append('archive_file_text_5',archive_file_text_5);
  data.append('archive_status',archive_status);
  data.append('archive_content',archive_content);

  try{
      const response = await Axios.post(
          `${base_url}main/update_archive`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//자료실를 삭제하는 API입니다.
export const delete_archive = async (
  archive_ID_array,
) => {

  let data = new FormData();
  data.append('archive_ID_array',archive_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_archive`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}