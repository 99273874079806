//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import {
  insert_optimum_film_banner,
  get_optimum_film_banner_one,
  base_url_export,
  update_optimum_film_banner,
} from "../../../Axios";

import * as H from '../../../Styles/StyleComponentHan';

//이미지를 사용하고 싶을때 아래와 같이 이미지 경로를 입력하여 사용
import no_image from '../../../Images/Etc/no_image.gif';

import { Editor } from '@tinymce/tinymce-react';

import {
  editor_init_data,
  editor_init_data_mo,
} from "../../../Variable";

export function OptimumFilmBannerManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 OptimumFilmBannerManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/OptimumFilmBannerManagementAdd?optimum_film_banner_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //optimum_film_banner_ID=2를 쓸 수 있다.
  //즉 optimum_film_banner_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const optimum_film_banner_ID_param = params.get("optimum_film_banner_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [optimumFilmBannerName, setOptimumFilmBannerName] = useState('');
  const [optimumFilmBannerImage, setOptimumFilmBannerImage] = useState('');
  const [optimumFilmBannerLink, setOptimumFilmBannerLink] = useState('');

  //컨트롤 데이터
  const [optimumFilmBannerImageURL, setOptimumFilmBannerImageURL] = useState('');

  //에러 출력 데이터
  const [optimumFilmBannerNameError, setOptimumFilmBannerNameError] = useState('');
  const [optimumFilmBannerImageError, setOptimumFilmBannerImageError] = useState('');

  const editorRef = useRef(null);

  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [htmlStringMobile, setHtmlStringMobile] = useState("");
  const [initialValueMobile, setInitialValueMobile] = useState("");

  //컨트롤 함수
  const optimum_film_banner_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(optimumFilmBannerName === ''){setOptimumFilmBannerNameError('옵티멈필름 배너명을 등록해 주세요.'); continue_add = false;}else{setOptimumFilmBannerNameError('');}
    if(optimumFilmBannerImageURL === ''){setOptimumFilmBannerImageError('옵티멈필름 배너이미지를 등록해 주세요.'); continue_add = false;}else{setOptimumFilmBannerImageError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(optimum_film_banner_ID_param === null){
        insert_optimum_film_banner(
          optimumFilmBannerName,
          optimumFilmBannerImage,
          optimumFilmBannerLink,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/OptimumFilmBannerManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_optimum_film_banner(
          optimum_film_banner_ID_param,
          optimumFilmBannerName,
          optimumFilmBannerImage,
          optimumFilmBannerLink,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/OptimumFilmBannerManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 optimum_film_banner_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(optimum_film_banner_ID_param !== null){
      //특정 ID의 옵티멈필름배너 데이터를 가져오기 위한 함수
      get_optimum_film_banner_one(optimum_film_banner_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setOptimumFilmBannerName(result[0].optimum_film_banner_name);
        setOptimumFilmBannerLink(result[0].optimum_film_banner_link);
        //여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서
        //export로 선언하여 사용하는 것이다.
        setOptimumFilmBannerImageURL(base_url_export + result[0].optimum_film_banner_image);
        setInitialValue(result[0].optimum_film_banner_editor);
        setInitialValueMobile(result[0].optimum_film_banner_editor_mobile);
      })
    }
  },[optimum_film_banner_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메인화면 관리 - 옵티멈 썬팅필름 배너 {optimum_film_banner_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  optimum_film_banner_check();
                }}
              >
                {/* 옵티멈필름배너 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {optimum_film_banner_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_film_banner_name'>배너명<span> * </span></label>
              <input 
                type='text' 
                id = 'optimum_film_banner_name' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumFilmBannerName(event_data.target.value);
                }} 
                placeholder = "옵티멈 썬팅필름 배너명을 입력해 주세요."
                value={optimumFilmBannerName}
              />
              <H.admin_errorBox className="marginLeft150">{optimumFilmBannerNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_film_banner_link'>배너링크</label>
              <input 
                type='text' 
                id = 'optimum_film_banner_link' 
                maxLength={300} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumFilmBannerLink(event_data.target.value);
                }} 
                placeholder = "옵티멈 썬팅필름 배너 클릭 시 이동을 원하는 링크 주소를 작성해 주세요. 없으면 생략 가능 합니다."
                value={optimumFilmBannerLink}
              />
            </H.admin_labelBox>

            <H.admin_labelImg>
              <p className="labelImgTit">배너이미지</p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>배너이미지<span> * </span></p>
                      <p className="labelImgInfo">사이즈 800 x 640px</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='optimum_film_banner_image'>
                        {/* 옵티멈필름배너 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        {optimum_film_banner_ID_param === null ? ' 추가 ' : ' 변경 '}
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='optimum_film_banner_image'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      accept='image/gif, image/jpeg, image/png'
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        //자세한 내용은 위 옵티멈필름배너이미지 추가에 넣어났습니다.
                        if(event_data.target.files[0] !== undefined){
                          setOptimumFilmBannerImage(event_data.target.files[0]);
                          const reader = new FileReader();
                          reader.readAsDataURL(event_data.target.files[0]);
                          reader.onloadend = () => {
                            setOptimumFilmBannerImageURL(reader.result);
                          };
                        }
                      }} 
                    />
                    <img className="admin_mobileImg"
                      src={
                        //자세한 내용은 위 옵티멈필름배너이미지 추가에 넣어났습니다.
                        optimumFilmBannerImageURL === '' ?
                        no_image : optimumFilmBannerImageURL
                      } 
                      alt="옵티멈필름배너이미지"
                    />
                </div>
                <H.admin_errorBox className="marginLeft150">{optimumFilmBannerImageError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>
            <H.listInfoText className="marginTop50">
              <label htmlFor='news_event_editor' className="listInfoTextBold">
                옵티멈 썬팅필름 배너 세부내용 사용 방법 안내<span> * </span>
              </label>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              PC용 에디터
              <span>(스크린 최소 사이즈 기준 1200px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValue}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
            <H.admin_editorBoxTit>
              모바일용 에디터
              <span>(스크린 최소 사이즈 기준 480px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValueMobile}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data_mo}
              onEditorChange={(newValue, editor) => {
                setHtmlStringMobile(newValue);
              }}
            />
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default OptimumFilmBannerManagementAdd;
