//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import {
  insert_optimum_tools,
  get_optimum_tools_one,
  base_url_export,
  update_optimum_tools,
} from "../../../Axios";
import * as H from '../../../Styles/StyleComponentHan';
//이미지를 사용하고 싶을때 아래와 같이 이미지 경로를 입력하여 사용
import no_image from '../../../Images/Etc/no_image.gif';


export function OptimumToolsManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 OptimumToolsManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/OptimumToolsManagementAdd?optimum_tools_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //optimum_tools_ID=2를 쓸 수 있다.
  //즉 optimum_tools_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const optimum_tools_ID_param = params.get("optimum_tools_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [optimumToolsName, setOptimumToolsName] = useState('');
  const [optimumToolsImage, setOptimumToolsImage] = useState('');
  const [optimumToolsImageMobie, setOptimumToolsImageMobie] = useState('');
  const [optimumToolsLink, setOptimumToolsLink] = useState('');
  const [optimumToolsStatus, setOptimumToolsStatus] = useState('1');

  //컨트롤 데이터
  const [optimumToolsImageURL, setOptimumToolsImageURL] = useState('');
  const [optimumToolsImageMobieURL, setOptimumToolsImageMobieURL] = useState('');

  //에러 출력 데이터
  //const [optimumToolsNameError, setOptimumToolsNameError] = useState('');
  const [optimumToolsImageError, setOptimumToolsImageError] = useState('');
  const [optimumToolsImageMobieError, setOptimumToolsImageMobieError] = useState('');

  //컨트롤 함수
  const optimum_tools_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    //if(optimumToolsName === ''){setOptimumToolsNameError('옵티멈 툴명을 등록해 주세요.'); continue_add = false;}else{setOptimumToolsNameError('');}
    if(optimumToolsImageURL === ''){setOptimumToolsImageError('옵티멈 툴 PC용 이미지를 등록해 주세요.'); continue_add = false;}else{setOptimumToolsImageError('');}
    if(optimumToolsImageMobieURL === ''){setOptimumToolsImageMobieError('옵티멈 툴 모바일용 이미지를 등록해 주세요.'); continue_add = false;}else{setOptimumToolsImageMobieError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(optimum_tools_ID_param === null){
        insert_optimum_tools(
          optimumToolsName,
          optimumToolsImage,
          optimumToolsImageMobie,
          optimumToolsLink,
          optimumToolsStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/OptimumToolsManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_optimum_tools(
          optimum_tools_ID_param,
          optimumToolsName,
          optimumToolsImage,
          optimumToolsImageMobie,
          optimumToolsLink,
          optimumToolsStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/OptimumToolsManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 optimum_tools_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(optimum_tools_ID_param !== null){
      //특정 ID의 옵티멈 툴 데이터를 가져오기 위한 함수
      get_optimum_tools_one(optimum_tools_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setOptimumToolsName(result[0].optimum_tools_name);
        setOptimumToolsLink(result[0].optimum_tools_link);
        //여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서
        //export로 선언하여 사용하는 것이다.
        setOptimumToolsImageURL(base_url_export + result[0].optimum_tools_image);
        setOptimumToolsImageMobieURL(base_url_export + result[0].optimum_tools_image_mobile);
        setOptimumToolsStatus(result[0].optimum_tools_status);
      })
    }
  },[optimum_tools_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메뉴관리 관리 - Optimum Tools {optimum_tools_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  optimum_tools_check();
                }}
              >
                {/* 옵티멈 툴 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {optimum_tools_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_tools_name'>옵티멈 툴명{/* <span> * </span> */}</label>
              <input 
                type='text' 
                id = 'optimum_tools_name' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumToolsName(event_data.target.value);
                }} 
                placeholder = "옵티멈 툴명 또는 배너 설명을 입력해 주세요."
                value={optimumToolsName}
              />
              {/* <H.admin_errorBox className="marginLeft150">{optimumToolsNameError}</H.admin_errorBox> */}
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_tools_link'>옵티멈 툴링크</label>
              <input 
                type='text' 
                id = 'optimum_tools_link' 
                maxLength={300} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumToolsLink(event_data.target.value);
                }} 
                placeholder = "옵티멈 툴 클릭 시 이동을 원하는 링크 주소를 작성해 주세요. 없으면 생략 가능 합니다."
                value={optimumToolsLink}
              />
            </H.admin_labelBox>


            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}

            {/* 여기 어디서 나오는지 확인해볼것 */}
            {optimum_tools_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='optimum_tools_status'>사용 여부</label>
              <select
                value={optimumToolsStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setOptimumToolsStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }


            <H.admin_labelImg>
              <p className="labelImgTit">옵티멈 툴이미지</p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>PC용 배너이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 800 x 300px</p>
                    <label htmlFor='optimum_tools_image'>
                      {/* 옵티멈 툴 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                      {optimum_tools_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'optimum_tools_image'
                    //이건 input type='file'일 경우만 가능한건데
                    //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                    accept='image/gif, image/jpeg, image/png'
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      //if문을 사용하게 되는데 if문은 조건이 맞는지 안맞는지에 따라 아래 자바스크립트가 사용이 됩니다.
                      //아래 if문을 해석하면 event_data.target.files[0] !== undefiend 라는 것은
                      //사진파일이 정의되지 되지 않은게 아니라면 아래 자바스크립트를 실행해라 뜻입니다.
                      if(event_data.target.files[0] !== undefined){
                        //setOptimumToolsImage를 통해서 사진파일 원본자체를 useState를 통해서 저장시키고
                        //나중에 백엔드에 사진데이터를 보낼겁니다.
                        setOptimumToolsImage(event_data.target.files[0]);
                        //또 Filereader라는 함수를 통해
                        const reader = new FileReader();
                        //받아온 사진의 URL을 읽어서 
                        reader.readAsDataURL(event_data.target.files[0]);
                        //그리고 onloadend라고 사진파일을 다 읽었다면
                        reader.onloadend = () => {
                          //이미지 URL에 그 결과를 넣으면 실시간으로 사진이 바뀝니다.
                          setOptimumToolsImageURL(reader.result);
                          };
                      }
                    }} 
                  />
                  <img 
                    className="admin_optimumToolsPC"
                    src={
                      //이미지URL이 비어있다면 no_image를 가져오는데
                      //no_image는 위에 import해서 가져온 사진입니다.
                      optimumToolsImageURL === '' ?
                      no_image : optimumToolsImageURL
                    } 
                    alt="옵티멈 툴이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{optimumToolsImageError}</H.admin_errorBox>
              </div>
              
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>모바일용 배너이미지<span> * </span></p>
                      <p className="labelImgInfo">사이즈 600 x 300px</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='optimum_tools_image_mobile'>
                        {/* 옵티멈 툴 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        {optimum_tools_ID_param === null ? ' 추가 ' : ' 변경 '}
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='optimum_tools_image_mobile'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      accept='image/gif, image/jpeg, image/png'
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        //자세한 내용은 위 옵티멈 툴이미지 추가에 넣어났습니다.
                        if(event_data.target.files[0] !== undefined){
                          setOptimumToolsImageMobie(event_data.target.files[0]);
                          const reader = new FileReader();
                          reader.readAsDataURL(event_data.target.files[0]);
                          reader.onloadend = () => {
                            setOptimumToolsImageMobieURL(reader.result);
                          };
                        }
                      }} 
                    />
                    <img
                      className="admin_optimumToolsMo"
                      src={
                        //자세한 내용은 위 옵티멈 툴이미지 추가에 넣어났습니다.
                        optimumToolsImageMobieURL === '' ?
                        no_image : optimumToolsImageMobieURL
                      } 
                      alt="옵티멈 툴 모바일 이미지"
                    />
                </div>
              <H.admin_errorBox className="marginLeft150">{optimumToolsImageMobieError}</H.admin_errorBox>
            </div>
            
            
            </H.admin_labelImg>
            

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default OptimumToolsManagementAdd;
