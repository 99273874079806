//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState} from 'react';//Header 컴포넌트를 가져온 것이고
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import * as H from '../../Styles/StyleComponentHan';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  get_notice_one,
  base_url_export
} from "../../Axios";

function NoticeDetail() {
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [noticeData, setNoticeData] = useState([]); //백엔드에서 가져온 에디터 데이터를 담기위한 useState

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //파라미터 데이터
    //파라미터 데이터는 AdminconstructionPointManagement여기에서
    //체크박스 누르고 수정을 누르면
    //http://localhost:3000/NoticeDetail?notice_ID=2
    //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
    //notice_ID=2를 쓸 수 있다.
    //즉 notice_ID 고유값인 2를 쓸 수 있는 것이다.
    const params = new URLSearchParams(window.location.search);
    const notice_ID = params.get("notice_ID");

    //자세한 내용은 src/Axios.js 파일 확인
    get_notice_one(notice_ID).then(result_data=>{     
      //setNoticeData를 활용하여 noticeData 할당
      setNoticeData(result_data[0]);
    })
  },[]);
  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>
      <H.editorView_mainWrap>
        <div className="editorView_mainInner">
          <H.newsEventDetail>
            <div className="newsEventDetailBox">
              {/* <p className="newsEventDetailRegiste">{noticeData.notice_registe}</p> */}
              <p className="newsEventDetailTit">{noticeData.notice_title}</p>
            </div>

            {/* 모바일인지 PC인지에 따라서 Editor데이터를 다르게 표현 */}
            <div className="editorView_mobile" dangerouslySetInnerHTML={{__html: noticeData.notice_content}}/>
            <div className="editorView_pc" dangerouslySetInnerHTML={{__html: noticeData.notice_content}}/>
          </H.newsEventDetail>
          <H.editorView_archiveData>
            <p className="editorView_archiveDataTit">첨부파일</p>
            {noticeData.notice_file_text_1 !== '' && 
            <a href = {base_url_export + noticeData.notice_file_1} target='_blank' rel="noreferrer">{noticeData.notice_file_text_1}</a>
            }
            {noticeData.notice_file_text_2 !== '' && 
            <a href = {base_url_export + noticeData.notice_file_2} target='_blank' rel="noreferrer">{noticeData.notice_file_text_2}</a>
            }
            {noticeData.notice_file_text_3 !== '' && 
            <a href = {base_url_export + noticeData.notice_file_3} target='_blank' rel="noreferrer">{noticeData.notice_file_text_3}</a>
            }
            {noticeData.notice_file_text_4 !== '' && 
            <a href = {base_url_export + noticeData.notice_file_4} target='_blank' rel="noreferrer">{noticeData.notice_file_text_4}</a>
            }
            {noticeData.notice_file_text_5 !== '' && 
            <a href = {base_url_export + noticeData.notice_file_5} target='_blank' rel="noreferrer">{noticeData.notice_file_text_5}</a>
            }
          </H.editorView_archiveData>
        </div>
      </H.editorView_mainWrap>
      <Footer/>
    </>
  );
}

export default NoticeDetail;
