//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState} from 'react';//Header 컴포넌트를 가져온 것이고
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import * as H from '../../Styles/StyleComponentHan';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  get_editor_data,
} from "../../Axios";
//모바일인지 아닌지 판단하기 위한 패키지
import {isMobile} from 'react-device-detect';



function OptimumFilmType() {
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [editorData, setEditorData] = useState([]); //백엔드에서 가져온 에디터 데이터를 담기위한 useState
  const [editorMobileData, setEditorMobileData] = useState([]); //백엔드에서 가져온 모바일 에디터 데이터를 담기위한 useState

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 Editor내용을 가져오기 위한 Function이고 
    //'1'은 옵티멈윈도우필름종류 에디터에 대한 구분 값이다.
    //자세한 내용은 src/Axios.js 파일 확인
    get_editor_data('1').then(result_data=>{      
      //롤setEditorData를 활용하여 editorData에 할당
      setEditorData(result_data[0].editor_content);
      setEditorMobileData(result_data[0].editor_content_mobile);
    })
  },[]);
  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>
      <H.editorView_mainWrap>
        <div className="editorView_mainInner">
          {/* 모바일인지 PC인지에 따라서 Editor데이터를 다르게 표현 */}
          <div className="editorView_mobile" dangerouslySetInnerHTML={{__html: isMobile ? editorMobileData : editorData}}/>
          <div className="editorView_pc" dangerouslySetInnerHTML={{__html: isMobile ? editorMobileData : editorData}}/>
        </div>
      </H.editorView_mainWrap>
      <Footer/>
    </>
  );
}

export default OptimumFilmType;
