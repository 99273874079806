//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';

import * as H from '../../Styles/StyleComponentHan';

//아래 함수들에 대한 자세한 설명은 Axios.js 파일을 참고하세요
import {
  get_notice,
  delete_notice,
} from "../../Axios";

export function NoticeManagement() {  
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [NoticeData, setNoticeData] = useState([]); //백엔드에서 가져온 옵티멈필름배너 데이터를 담기위한 useState

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

  //이거는 수정버튼 눌렀을 때 체크하는 함수 입니다.
  const check_notice_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      //이건 GET방식으로 URL을 이동하는 것인데
      //?뒤에 데이터를 포함시켜 이동시킬 수 있다.
      //http://localhost:3000/NoticeManagementAdd?notice_ID=2
      //이렇게 옵티멈필름배너의 고유 ID를 포함시켜서 날리는데
      //그럼 NoticeManagementAdd여기에서 notice_ID가 2라는 것을 활용할 수 있다.
      window.location.href = `/NoticeManagementAdd?notice_ID=${tabulatorRef.current.getSelectedData()[0].notice_ID}`;
    }
  }

  //이거는 삭제버튼 눌렀을 때 체크하는 함수 입니다.
  const check_notice_delete = () =>{
    if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      //삭제할 옵티멈필름배너 고유ID를 담을 임시배열
      let temp_delete_array = [];
      //forEact에 대한 설명은 위에 설명함
      tabulatorRef.current.getSelectedData().forEach((element)=>{
        temp_delete_array.push(element.notice_ID);
      })
      delete_notice(temp_delete_array).then((result)=>{
        //result === undefined 이라는 것은 백엔드에서 update 처리가 안될시 결과가 undefiend로 결과가 오는데 
        //그때 alert을 출력합니다. 
        if(result === undefined){
          alert('삭제가 되지 않았습니다. 관리자한테 확인해주세요.');
        }else{
          alert('삭제가 완료 되었습니다.');
          window.location.reload();
        }
      })
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 옵티멈필름배너들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_notice().then(result_data=>{
      //옵티멈필름배너 데이터를 setNoticeData를 활용하여 NoticeData에 할당
      setNoticeData(result_data);
      //만약에 옵티멈필름배너 데이터를 어떻게 가져왔나를 확인하고 싶으면
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
    })
  },[]);

  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>게시판 관리 - 공지사항 관리</p>
            <div>
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{window.location.href = "/NoticeManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_notice_edit();}}>수정
              </H.admin_changeBtn>
              <H.admin_deleteBtn
                //삭제 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_notice_delete();}}>삭제
              </H.admin_deleteBtn>
            </div>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            <H.listInfoText>
            </H.listInfoText>
            {/* 이것도 리액트의 꽃인데
            NoticeData 이게 useState를 통해서 기본값을 []으로 잡았잖아요
            즉 NoticeData.length라는 것은 만약에 DB에서 데이터를 가져오지 않았다면 계속 빈 배열이고
            만약 2개의 데이터를 가져왔다면
            [
              {notice_ID : 1},
              {notice_ID : 2},
            ]
            위처럼 2개의 데이터를 가져오게 되는데
            아래 NoticeData.length라는 함수는
            저 배열안에 객체(객체라고 하면 {}이안에 들어가는 데이터에요)의 수를 판단해주거든요
            그래서 3항연산자를 활용해서
            NoticeData.length !== 0 ? 이말은
            NoticeData의 배열안에 객체의 갯수가 0이아니라면
            <ReactTabulator
              data={NoticeData}
              columns={columns}
              layout={"fitData"}
            />
            tabulator를 출력하는거고
            갯수가 0이라면
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            이놈을 출력하게 되는거죠
            만약에 NoticeData.length !== 0 이거를 NoticeData.length === 0 으로 바꾸면
            거꾸로라서 데이터가 없습니다.를 CSS처리 할 수 있죠
            처리가 완료되면 다시 !==으로 바꾸고 
            */}
            {
            NoticeData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={NoticeData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "notice_ID", headerSort:false},
                { title: "공지사항 타이틀", field: "notice_title", headerSort:false},
                { title: "등록 일자", field: "notice_registe", headerSort:false},
                { title: "수정 일자", field: "notice_modified", headerSort:false},
              ]}
              layout={"fitData"}
              options={{
                //row를 움직일 수 있게
                movableRows: true,
              }}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default NoticeManagement;
