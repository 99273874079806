// 공백 제거 및 폰번호 변경
// 이건 정규식이라고 하는데 인터넷 찾아보면 잘 나와있습니다.
// 즉 어떠한 형식으로 입력을 받게되면 자동으로 치환해서 바꿔주는 역활을 하게 됩니다.
export const removeWhitespacePhone = text => {
  const regex = /\s/g;
  return text.replace(regex, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}

export const removeWhitespace = (text) => {
  const regex = /\s/g;
  return text.replace(regex, '');
};

// 전화번호형식 확인
export const validatePhone = phone => {
  const regex = /^\d{3}-\d{3,4}-\d{4}$/;
  return regex.test(phone);
}
