//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState} from 'react';
//get_session_data_admin 대한 설명은 Axios.js 파일을 참고하세요
import {get_session_data_admin} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import admin_sideMenuLogo from '../../Images/optimum_logo_w.png'

export function AdminHeader() {  
  const [menuPermissionArray, setMenuPermissionArray] = useState('');

  // useEffect는 React에서 제공하는 hook인데 
  // hook에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // useEffect라는 hook은 앱이 처음실행했을 시 딱 한번만 실행할 수 있는 자바스크립트만
  // 담으면 됩니다.
  // 왜 필요하면 react는 useState를 사용하게 되면 랜더링을 하게되는데
  // useState에 대한 설명은  src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // 랜더링이라는 것은 다시한번 소스코드를 읽는다는 건데요.
  // 근데 useEffect안에 있는 자바스크립트는 소스코드를 읽지않습니다.
  // 그래서 필요한겁니다. 
  // 랜더링 될때마다 세션체크는 할필요가 없는거잖아요.
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_admin().then((result)=>{
      if(result.user_ID === '' || result.user_ID === undefined){
        alert('로그인 시간이 만료되었습니다.');
        window.location.href = "/AdminLogin";
      }else{
        setMenuPermissionArray(result.menu_permission_array);
      }
    })
  },[]);
  
  return (
    //여기는 헤더입니다. 일단 크게 div로 잡아났는데
    //좌측에 트리메뉴로 넣을껀데 숨길건지 아니면 메뉴 계속 보여줄지는 고민해서 
    //프론트 작업 부탁합니다~~
    //참고로 export를 선언하면 다른 파일에서 사용이 가능합니다.
    //이게 파일하나하나를 리엑트에서는 컴포넌트라고 하는데
    //즉 다른 컴포넌터에서 헤더파일을 import해서 가져올 수 있습니다.
    //즉 헤더는 모든 메뉴에 들어가기 떄문에 AdminHeader라는 명칭으로 컴포넌트를 하나 생성했고
    //다른데에서 import해서 가져와서 쓸겁니다.
    //자세한건 AdminMain을 참고하세요.
    //현재 메뉴수가 작아서 계속 보여주는 형식으로 할겁니다.

    <H.admin_HeaderWrap>
      <H.admin_sideMenuBox>
        <H.admin_sideMenuLogo>
          <a href='/AdminMain'>
            <img src = {admin_sideMenuLogo} alt="옵티멈윈도우필름 로고"></img>
          </a>
        </H.admin_sideMenuLogo>

        {menuPermissionArray.includes("1") && 
          <>
            <H.admin_MenuTreeTop>시스템 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("1-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/MenuPermissionManagement'>메뉴권한 관리</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("1-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/UserManagement'>사용자 관리</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("7") && 
          <>
            <H.admin_MenuTreeTop>게시판 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("7-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/NoticeManagement'>공지사항 관리</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("7-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/ArchiveManagement'>자료실 관리</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("2") && 
          <>
            <H.admin_MenuTreeTop>메인화면 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("2-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminRollingBannerManagement'>롤링 배너</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("2-4") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminSmallBannerManagement'>소형 이미지 배너</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("2-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OptimumFilmBannerManagement'>옵티멈 썬팅필름 배너</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("2-3") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OurStoryBannerManagement'>대형 이미지 배너</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("3") && 
          <>
            <H.admin_MenuTreeTop>메뉴 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("3-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOfTypeManagement'>옵티멈 썬팅필름</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-6") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOfBuildingManagement'>옵티멈 건물필름</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-7") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOfPpfManagement'>옵티멈 PPF</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-8") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOfChemicalManagement'>옵티멈 캐미컬</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOfPriceSpecManagement'>가격/스펙</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-3") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OurStoryManagement'>우리의 이야기</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-4") && 
              <H.admin_MenuTreeMiddle>
                <a href='/NewsEventManagement'>뉴스 & 이벤트</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("3-5") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OptimumToolsManagement'>Optimum Tools</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("4") && 
          <>
            <H.admin_MenuTreeTop>서비스 메뉴 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("4-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/BusinessAreaManagement'>사업 영역</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("4-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/TechnologyAreaManagement'>기술 영역</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }

        {menuPermissionArray.includes("5") && 
          <>
            <H.admin_MenuTreeTop>시공점 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("5-1") && 
              <H.admin_MenuTreeMiddle>
                <a href='/AdminOCPointManagement'>시공점</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }
        {menuPermissionArray.includes("6") && 
          <>
            <H.admin_MenuTreeTop>보증서 관리</H.admin_MenuTreeTop>
            {menuPermissionArray.includes("6-4") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OldGuaranteeManagement'>(구)보증서 관리</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("6-1") && 

              <H.admin_MenuTreeMiddle>
                <a href='/GuaranteeManagement'>보증서 관리</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("6-2") && 
              <H.admin_MenuTreeMiddle>
                <a href='/OptimumFilmTypeManagement'>옵티멈 썬팅필름 종류</a>
              </H.admin_MenuTreeMiddle>
            }
            {menuPermissionArray.includes("6-3") && 
              <H.admin_MenuTreeMiddle>
                <a href='/GuaranteePeriodManagement'>보증기간</a>
              </H.admin_MenuTreeMiddle>
            }
          </>
        }
      </H.admin_sideMenuBox>
    </H.admin_HeaderWrap>
  );
}

//실제사용하는 것은 아니고 샘플입니다.
export function AdminHeader2() {  
  return (
    <div>
      <p>메뉴</p>
      <div>
        <p>메인화면 관리</p>
        <div>
          <a href='/AdminRollingBannerManagement'>롤링 배너관리</a>
        </div>
      </div>
    </div>
  );
}

//export default AdminHeader라는 것은 사실 하나의 컴포넌트에 여러개의 함수를 export 시킬 수 있는데
//default라는것은 기본값으로 가져간다는 것인데 위에 AdminHeader와 AdminHeader2중에 AdminHeader를 호출하면 
//AdminHeader를 기본값으로 준다는 것입니다. 근데 보통 하나의 컴포넌트안에 하나의 함수를 사용하는게 
//유지보수 차원에 편하고 좋아요 만약 2개를 만든다면 파일을 하나 만드는게 훨씬 편한데
//뭐 개발자마다 성향이 다른거라 뭐가 맞다고 표현하기 힘드네요
export default AdminHeader;
