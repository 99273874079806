//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';//Header 컴포넌트를 가져온 것이고
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  base_url_export,
  get_rolling_banner_status_1,
  get_optimum_film_banner,
  get_small_banner,
  get_fix_banner,
} from "../../Axios";
//롤링 배너를 위한 패키지를 설치했으며
//설치방법은 npm i swiper
//공식 사이트는 https://swiperjs.com/react를 참고하면 됩니다. 
import { Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, EffectFade, Pagination, Autoplay } from 'swiper';
import * as H from '../../Styles/StyleComponentHan';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import sliderRight from '../../Images/Etc/sliderRight.png';
import sliderLeft from '../../Images/Etc/sliderLeft.png';
import quickLinksArrow from '../../Images/Etc/quickLinksArrow.png';
//모바일인지 아닌지 판단하기 위한 패키지
import {isMobile} from 'react-device-detect';

let swiper_loop_count = 1;
function Main() {

  const swiperRef = useRef();

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [rollingBannerData, setRollingBannerData] = useState([]); //백엔드에서 가져온 롤링 배너 데이터를 담기위한 useState
  const [smallBannerData, setSmallBannerData] = useState([]);
  const [optimumFilmBannerData, setOptimumFilmBannerData] = useState([]); //백엔드에서 가져온 옵티멈 필름 배너 데이터를 담기위한 useState
  const [ourStoryBannerData, setOurStoryBannerData] = useState([
    {
      fix_banner_link:'',
      fix_banner_image:'',
      fix_banner_image_mobile:'',
      fix_banner_title:'',
    }
  ]); //백엔드에서 가져온 대형 이미지 배너 데이터를 담기위한 useState


  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 롤링 배너들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_rolling_banner_status_1().then(result_data=>{
      //롤링 배너 데이터를 setOptimumFilmBannerData 활용하여 rollingBannerData에 할당
      setRollingBannerData(result_data);
      //만약에 롤링 배너 데이터를 어떻게 가져왔나를 확인하고 싶으면
      //아래 console.log(result_data)주석을 풀어서
      //크롬창에 F12누르면 콘솔에 찍힙니다.
      /* console.log(result_data); */
      setInterval(() => {
        if(swiper_loop_count === result_data.length){
          swiperRef.current.swiper.slideTo(0);
          swiper_loop_count = 1;
        }else{
          swiperRef.current.swiper.slideNext();
          swiper_loop_count++;
        }
      }, 5000);
    })
    //이거는 데이터베이스에 있는 옵티멈윈도우필름배너들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_optimum_film_banner().then(result_data=>{
      //옵티멈필름배너 데이터를 setOptimumFilmBannerData 활용하여 optimumFilmBannerData에 할당
      setOptimumFilmBannerData(result_data);
    })

    get_small_banner().then(result_data=>{
      setSmallBannerData(result_data);
    })

    //대형 이미지 배너 데이터를 가져오기 위한 Function이고
    //자세한 내용은 src/Axios.js 파일 확인
    // '1'은 대형 이미지 배너입니다.
    get_fix_banner('1').then((result) => {
      //데이터를 가져오면 데이터를 집어넣어준다.
      setOurStoryBannerData(result);
    })
  },[]);

  return (
    <>
      {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
      <Header/>

      <H.home_Wrap>
        <H.home_mainSliderWrap>
          <Swiper className="home_mainSliderBox"
            ref={swiperRef}
            //안에 속성은 Swiper에서 제공하는데로 그대로 쓰면 된다. 
            //공식사이트는 https://swiperjs.com/react이며 여기서 참고해서 사용하면 된다
            //이건 한페이지당 몇개를 보여줄 것인가에 대한 속성갑싱다.
            slidesPerView={1}
            //아래는 제공하는 모듈을 가져와서 쓴건데 자세한건 공식사이트 참고
            modules={[
              Navigation, // 네비게이션 모듈(화살표가 클릭하면 움직일 수 있게)
              EffectFade, // 움직일때 이펙트
              Pagination, // 페이저 쓰고 싶을때
              Autoplay, // 자동이동 쓰고 싶을 때
            ]}
            spaceBetween={50}
            //네이게이션에 대한 className 일치
            pagination={{ clickable: true }}  //페이지 클릭 시 이동 허용
            effect="fade" //페이드 효과
          >
            {/* map함수도 프론트 개발자들의 꽃인데
            앞에 롤링 데이터를 Axios를 통해서 데이터를 받았고
            그 데이터의 반복문을 위해 .map을 사용한다
            rollingBannerData가 처음에 useState의 초기값으로 ''을 주었기떄문에
            ''이 아니라면 .map의 반복문을 사용하고
            롤링데이터를 반복하면서 돌리면서 HTML을 뿌리게 된다.
            SwiperSlide는 Swiper에서 제공하는 패키지 명이라 나도 자세히 모른다. */}
            {rollingBannerData !== '' && rollingBannerData.map((element, index) => 
              <SwiperSlide
              key={index}>
                <div>
                  {/* onClick event에 대한 내용은 src/Admin/Login.js 참고*/}
                  <a href={element.rolling_banner_link} target="_blank" rel="noreferrer">
                    {/* 여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서 export로 선언하여 사용하는 것이다. 
                    여기서 style-component를 사용한 이유는 반응형에 맞춰서 이미지를 PC로 할지 모바일로 할지에 대해서 판단하기 위해서
                    style-component를 사용했다.
                    src/Styles/StyleComponentRyu에 SlideImg를 참조하면 쉽게 알 수 있을 것이다.
                    */}
                    <H.SlideImg
                      rolling_banner_image = {base_url_export + element.rolling_banner_image}
                      rolling_banner_image_mobile = {base_url_export + element.rolling_banner_image_mobile}
                    />
                  </a>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          {/* 왼쪽 버튼 */}
          <H.home_mainSliderBtns className="slider_left_button" onClick={() => swiperRef.current.swiper.slidePrev()}>
            <img src = {sliderLeft} alt="왼쪽으로 이동" />
          </H.home_mainSliderBtns>
          
          {/* 오른쪽 버튼 */}
          <H.home_mainSliderBtns className="slider_right_button" onClick={() => swiperRef.current.swiper.slideNext()}>
            <img src = {sliderRight} alt="오른쪽으로 이동" />
          </H.home_mainSliderBtns>

        </H.home_mainSliderWrap>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionTit>
              OPTIMUM
            </H.main_sectionTit>

            <H.main_sectionSliderWrap>
                {smallBannerData !== '' && smallBannerData.map((element, index) => 
                  <H.main_sectionSliderBox
                    key={index}
                  >
                    <a
                      href={element.small_banner_link}
                      key={index}
                    >
                      <p className="main_sectionSliderTit">{element.small_banner_name}</p>
                      <p className="main_sectionSliderImg">
                        <img src={base_url_export + element.small_banner_image} alt="소형배너이미지"/>
                      </p>
                      
                    </a>
                  </H.main_sectionSliderBox>
                )}
                
            </H.main_sectionSliderWrap>
          </div>
        </H.main_sectionBox>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionTit>
              옵티멈 썬팅필름
            </H.main_sectionTit>

            <H.main_sectionSliderWrap>
                {optimumFilmBannerData !== '' && optimumFilmBannerData.map((element, index) => 
                  <H.main_sectionSliderBox
                    key={index}
                  >
                    <a
                      href={element.optimum_film_banner_link}
                      key={index}
                    >
                      <p className="main_sectionSliderTit">{element.optimum_film_banner_name}</p>
                      <p className="main_sectionSliderImg">
                        <img src={base_url_export + element.optimum_film_banner_image} alt="옵티멈필름배너이미지"/>
                      </p>
                      
                    </a>
                  </H.main_sectionSliderBox>
                )}
                
            </H.main_sectionSliderWrap>
          </div>
        </H.main_sectionBox>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionStory>
              <H.main_sectionTit>
                {ourStoryBannerData[0].fix_banner_title}
              </H.main_sectionTit>
              <a href={ourStoryBannerData[0].fix_banner_link}>
                  {/* 여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서 export로 선언하여 사용하는 것이다. 
                  여기서 style-component를 사용한 이유는 반응형에 맞춰서 이미지를 PC로 할지 모바일로 할지에 대해서 판단하기 위해서
                  style-component를 사용했다.
                  src/Styles/StyleComponentRyu에 SlideImg를 참조하면 쉽게 알 수 있을 것이다.
                  */}
                <img
                  src = {
                    isMobile ? 
                      base_url_export + ourStoryBannerData[0].fix_banner_image_mobile : 
                      base_url_export + ourStoryBannerData[0].fix_banner_image
                    }
                  alt='우리의 이야기 이미지'
                  />
              </a>
            </H.main_sectionStory>
          </div>
        </H.main_sectionBox>

        <H.main_sectionBox>
          <div className='main_sectionBoxInner'>
            <H.main_sectionTit>
              Quick Links
            </H.main_sectionTit>

            <H.main_sectionQuick>
              <a href='/OptimumFilmType'>
                <p>옵티멈 썬팅필름</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>

              <a href='/BusinessArea'>
                <p>서비스</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>

              <a href='/OCPoint'>
                <p>시공점 찾기</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>

              <a href='/NewsEvent'>
                <p>뉴스 & 이벤트</p>
                <img src ={quickLinksArrow} alt="퀵링크 바로가기"></img>
              </a>
            </H.main_sectionQuick>
          </div>
        </H.main_sectionBox>
      </H.home_Wrap>

      <Footer/>
    </>
  );
}

export default Main;
