//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import {
  insert_small_banner,
  get_small_banner_one,
  base_url_export,
  update_small_banner,
} from "../../../Axios";

import * as H from '../../../Styles/StyleComponentHan';

//이미지를 사용하고 싶을때 아래와 같이 이미지 경로를 입력하여 사용
import no_image from '../../../Images/Etc/no_image.gif';


export function AdminSmallBannerManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 AdminSmallBannerManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/AdminSmallBannerManagementAdd?small_banner_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //small_banner_ID=2를 쓸 수 있다.
  //즉 small_banner_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const small_banner_ID_param = params.get("small_banner_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [smallBannerName, setSmallBannerName] = useState('');
  const [smallBannerImage, setSmallBannerImage] = useState('');
  const [smallBannerLink, setSmallBannerLink] = useState('');

  //컨트롤 데이터
  const [smallBannerImageURL, setSmallBannerImageURL] = useState('');

  //에러 출력 데이터
  const [smallBannerNameError, setSmallBannerNameError] = useState('');
  const [smallBannerImageError, setSmallBannerImageError] = useState('');

  //컨트롤 함수
  const small_banner_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(smallBannerName === ''){setSmallBannerNameError('소형 이미지 배너명을 등록해 주세요.'); continue_add = false;}else{setSmallBannerNameError('');}
    if(smallBannerImageURL === ''){setSmallBannerImageError('소형 이미지 배너 PC용 이미지를 등록해 주세요.'); continue_add = false;}else{setSmallBannerImageError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(small_banner_ID_param === null){
        insert_small_banner(
          smallBannerName,
          smallBannerImage,
          smallBannerLink,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminSmallBannerManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_small_banner(
          small_banner_ID_param,
          smallBannerName,
          smallBannerImage,
          smallBannerLink,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminSmallBannerManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 small_banner_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(small_banner_ID_param !== null){
      //특정 ID의 소형 이미지 배너 데이터를 가져오기 위한 함수
      get_small_banner_one(small_banner_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setSmallBannerName(result[0].small_banner_name);
        setSmallBannerLink(result[0].small_banner_link);
        //여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서
        //export로 선언하여 사용하는 것이다.
        setSmallBannerImageURL(base_url_export + result[0].small_banner_image);
      })
    }
  },[small_banner_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메인화면 관리 - 소형 이미지 배너 {small_banner_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  small_banner_check();
                }}
              >
                {/* 소형 이미지 배너 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {small_banner_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='small_banner_name'>소형 이미지 배너명<span> * </span></label>
              <input 
                type='text' 
                id = 'small_banner_name' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setSmallBannerName(event_data.target.value);
                }} 
                placeholder = "소형 이미지 배너명을 입력해 주세요."
                value={smallBannerName}
              />
              <H.admin_errorBox className="marginLeft150">{smallBannerNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='small_banner_link'>소형 이미지 배너링크</label>
              <input 
                type='text' 
                id = 'small_banner_link' 
                maxLength={300} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setSmallBannerLink(event_data.target.value);
                }} 
                placeholder = "소형 이미지 배너 클릭 시 이동을 원하는 링크 주소를 작성해 주세요. 없으면 생략 가능 합니다."
                value={smallBannerLink}
              />
            </H.admin_labelBox>

            <H.admin_labelImg>
              <p className="labelImgTit">소형 이미지 배너이미지</p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>PC용 배너이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1920 x 815px</p>
                    <label htmlFor='small_banner_image'>
                      {/* 소형 이미지 배너 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                      {small_banner_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'small_banner_image'
                    //이건 input type='file'일 경우만 가능한건데
                    //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                    accept='image/gif, image/jpeg, image/png'
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      //if문을 사용하게 되는데 if문은 조건이 맞는지 안맞는지에 따라 아래 자바스크립트가 사용이 됩니다.
                      //아래 if문을 해석하면 event_data.target.files[0] !== undefiend 라는 것은
                      //사진파일이 정의되지 되지 않은게 아니라면 아래 자바스크립트를 실행해라 뜻입니다.
                      if(event_data.target.files[0] !== undefined){
                        //setSmallBannerImage를 통해서 사진파일 원본자체를 useState를 통해서 저장시키고
                        //나중에 백엔드에 사진데이터를 보낼겁니다.
                        setSmallBannerImage(event_data.target.files[0]);
                        //또 Filereader라는 함수를 통해
                        const reader = new FileReader();
                        //받아온 사진의 URL을 읽어서 
                        reader.readAsDataURL(event_data.target.files[0]);
                        //그리고 onloadend라고 사진파일을 다 읽었다면
                        reader.onloadend = () => {
                          //이미지 URL에 그 결과를 넣으면 실시간으로 사진이 바뀝니다.
                          setSmallBannerImageURL(reader.result);
                          };
                      }
                    }} 
                  />
                  <img 
                    src={
                      //이미지URL이 비어있다면 no_image를 가져오는데
                      //no_image는 위에 import해서 가져온 사진입니다.
                      smallBannerImageURL === '' ?
                      no_image : smallBannerImageURL
                    } 
                    alt="소형 이미지 배너이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{smallBannerImageError}</H.admin_errorBox>
              </div>
      
            </H.admin_labelImg>
            

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminSmallBannerManagementAdd;
