//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef} from 'react';
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import * as H from '../../Styles/StyleComponentHan';
//아래 함수들은 Axios.js 파일을 참고하세요
import {
  get_session_data_homepage,
  get_archive_use,
} from "../../Axios";
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';
import detail_icon from '../../Images/Etc/detail_icon.png';

function Archive() {

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [archiveData, setArchiveData] = useState([]); //백엔드에서 가져온 공지사항 데이터를 담기위한 useState

  // 테이블에 사용하는 아이콘
  const detailIcon = function(){
      return `
  <img 
    src=${detail_icon} 
    alt="디테일 이미지"
    style="width:15px;heihgt:15px"
  />`
  };
  
  // useEffect는 React에서 제공하는 hook인데 
  // hook에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // useEffect라는 hook은 앱이 처음실행했을 시 딱 한번만 실행할 수 있는 자바스크립트만
  // 담으면 됩니다.
  // 왜 필요하면 react는 useState를 사용하게 되면 랜더링을 하게되는데
  // useState에 대한 설명은  src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // 랜더링이라는 것은 다시한번 소스코드를 읽는다는 건데요.
  // 근데 useEffect안에 있는 자바스크립트는 소스코드를 읽지않습니다.
  // 그래서 필요한겁니다. 
  // 랜더링 될때마다 세션체크는 할필요가 없는거잖아요.
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_homepage().then((result)=>{
      if(result.construction_point_ID === '' || result.construction_point_ID === undefined){
        alert('로그인을 해주세요.');
        window.location.href = `/Login`;
      }else{
        setArchiveData([]);
        //이거는 데이터베이스에 있는 공지사항들을 가져오기 위한 Function이고 
        //자세한 내용은 src/Axios.js 파일 확인
        get_archive_use().then(result_data=>{
          setArchiveData(result_data);
        })
      }
    })
  },[]);
  
  return (
    <>
    {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
    <Header/>
    {/* <H.printNonViewBox>
      공지사항 발급은 PC에서만 확인 가능합니다.
    </H.printNonViewBox> */}

    <H.main_sectionBox>
      <H.home_boardWrap>
        {/* <H.printMainTitBox>
          <h1 className="printMainTit">공지사항 발급</h1>
          <p className="printMainSubTit">* 공지사항 발급은 PC에서만 가능합니다.</p>
        </H.printMainTitBox> */}
        <div className="home_boardInner">
          <p className="home_boardInnerTit">자료실</p>
        
          <div className="home_boardBox">
            {
            archiveData.length !== 0 ? 
            <ReactTabulator
              style = {{overflowY:"scroll", margin:"0 auto"}}
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={archiveData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                { title: "ID", field: "archive_ID", headerSort:false,
                  cellClick:function(e, cell){
                    window.location.href = `/ArchiveDetail?archive_ID=${cell.getRow().getData().archive_ID}`;
                  }
                },
                { title: "제목", field: "archive_title",
                  cellClick:function(e, cell){
                    window.location.href = `/ArchiveDetail?archive_ID=${cell.getRow().getData().archive_ID}`;
                  }
                },
                { title: "자세히 보기", formatter:detailIcon, hozAlign:"center", headerSort:false,
                  cellClick:function(e, cell){
                    window.location.href = `/ArchiveDetail?archive_ID=${cell.getRow().getData().archive_ID}`;
                  }
                },
              ]}
              options={{ 
                paginationSize:10,
                pagination:'local',
                paginationButtonCount:5, // 밑에 표시될 페이지 갯수
              }}
              layout={"fitData"}
              />
            : 
            <div>
              <p className="home_boardNodate">데이터가 없습니다.</p>  
            </div>
            }
          </div>
        </div>
      </H.home_boardWrap>
    </H.main_sectionBox>
    <Footer/>
    </>
  );
}

export default Archive;
