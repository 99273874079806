import styled from "styled-components";


// 폰트 사용 방법 -> font-weight : ${noto_m}; 
//const noto_eb = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 900;`;
const noto_b = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 700;`;
const noto_m = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 500;`;
const noto_r = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 400;`;
//const noto_l = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 300;`;
//const noto_t = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 100;`;

//컬러 지정 : 관리자페이지
const color_mainBlack = "#121212"
const color_darkGray = "#4a4a4a"
const color_darkMiddleGray = "#888"
const color_middleGray = "#aaa"
const color_lightGray = "#ddd"
const color_bgGray ="#f7f7f7"
const color_white ="#fff"
const color_pointNavy = "#0F335F"
const color_pointRed = "#f00"


//-------------------- admin --------------------
// admin page start  :: section_admin login
export const admin_LoginWrap = styled.div` 
    width: 100%;
    height: 100vh;
    min-width:900px;
    position: relative;
    
    & > img {
      width: 100%;
      height: 100%; 
      object-fit: cover;
    }
`;

export const admin_LoginInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width:1000px;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
`;

export const admin_LoginLogo = styled.div`
    width: 345px;
    height: 75px;
    background: url(${props => props.admin_logowhite}) no-repeat center 100%/100%;
`;

export const admin_LoginFormBox = styled.div`
    width: 460px;
    height: 580px;
    padding: 90px 50px 0; 
    box-sizing: border-box;
    background-color: ${color_white};
`;

export const admin_LoginTit = styled.div`
      height: 30px;
      line-height: 28px;   
      font-size: 28px;
      text-align: center;
      font-weight: ${noto_b};
      margin-bottom: 90px; 
`;

export const admin_LoginInputBox = styled.div`
    display: flex;    
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${color_lightGray};

    & > label {
      width: 80px;
      margin-right: 10px;
    }

    & > input{
      width: 100%;
    }
`;

export const admin_LoginBtn = styled.div`
    margin-top: 40px;
    margin-bottom: 20px; 
    line-height: 46px;
    text-align: center;
    background-color: ${color_mainBlack};
    color: ${color_white};
`;

export const admin_LoginSearch = styled.div`
    text-align: center; 

    & > span{
      display: inline-block; 
      width: 1px;
      height: 12px;
      background-color: ${color_darkMiddleGray};
      margin: 0 14px;
    }
`;

//section_admin header
export const admin_HeaderWrap = styled.div`
    width: 100%;
    min-width: 360px;
    position: relative;
    overflow-y:scroll;
`;

export const admin_sideMenuBox = styled.div`
    position: fixed;
    width: 210px;
    height: 100%;
    background-color: ${color_pointNavy};
    color: ${color_white}; 
    padding-top: 21px; 
    overflow: auto;

        & > div {
            width: 100%;
            margin: 0 auto;
        }

        &::-webkit-scrollbar{
            width:5px;
            background-color:${color_darkGray}; 
        }

        &::-webkit-scrollbar-thumb{
            background-color: ${color_pointNavy};
        }

        &::-webkit-scrollbar-track{
            background-color:${color_darkGray};
        }
`;

export const admin_sideMenuLogo = styled.div`
    width: 180px;
    padding: 0 20px 30px;

    & img{
      width: 100%;
      height: auto;
      object-fit: cover;
      padding-bottom: 20px; 
      border-bottom: 1px solid ${color_middleGray}; 
    }
`;

export const admin_MenuTreeTop = styled.div`
    width: 100%;
    height: 40px;
    padding-left:18px;
    font-size: 14px;
    line-height: 40px;
`;

export const admin_MenuTreeMiddle =  styled.div`
    width: 100%;
    height: 40px;
    font-size: 12px; 
    line-height: 40px; 
    background-color: ${color_mainBlack};

    & > a {
        color: ${color_middleGray};
        line-height: 40px;
        padding-left:26px;
    }

    &:last-child{
        margin-bottom:40px; 
    }
`;


//section_admin page
export const admin_MainWrap = styled.div`
    width: 100%;
    min-width: 1400px;
    min-height:100vh;
    padding-bottom: 100px;
    position: relative;
    background-color: ${color_bgGray};
`;

export const admin_rightWrap = styled.div`
    margin-left: 200px;
`;

export const admin_rightTitBox = styled.div`
    height: 80px;
    margin-bottom: 30px; 
    background-color: ${color_white};
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

export const admin_rightTitInner = styled.div`
    width: 1200px;     
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p{
        font-size: 20px;
        font-weight: ${noto_m}; 
    }
`;

// 등록 버튼일 경우 사용
export const admin_addBtn = styled.button`
    height: 40px;
    color: ${color_white};
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_pointNavy};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_changeBtn = styled.button`
    height: 40px;
    color: ${color_white};
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_darkGray};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_deleteBtn = styled.button`
    height: 40px;
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_bgGray};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_rightInfoBox = styled.div`
    
`;

export const admin_rightInfoInner = styled.div`
    width: 1200px; 
    margin: 0 auto;

    & button{
        display:block; 
        color: ${color_white};
        padding:16px 60px;
        margin: 30px auto;
        background-color: ${color_pointNavy};
        border: 1px solid ${color_middleGray};
        cursor: pointer;
    }
`;

export const listInfoText = styled.div`
    line-height: 24px; 
    color: ${color_pointNavy};
    margin-bottom: 30px;
    
    .listInfoTextBold{
        font-weight: ${noto_b};
    }

    & span{
        color: ${color_pointRed};
    }
`;

export const admin_editorBoxTit = styled.div`
    font-size: 24px; 
    line-height: 30px;
    font-weight: ${noto_b};
    color: ${color_pointNavy};
    margin: 40px 0 10px; 

    & span{
        font-size:16px;
        margin-left:10px; 
    }
`;

export const admin_labelBox = styled.div`
    padding: 20px;
    background-color: ${color_white};
    border:1px solid ${color_lightGray};
    margin-bottom: 20px; 

    & label{
        display: inline-block;
        width: 150px; 
        font-size: 16px;
        font-weight: ${noto_m};
    }

    & input{
        font-size: 14px; 
        width: 70%;
    }

    & .inputDate{
        width: 51%;
        padding:6px;
        background-color: ${color_bgGray};
        position:relative;
    }

    & .inputDate::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        top: 5px;
        width:20px;
        height:20px; 
        transform: translateX(-10px);
        padding-left: 3000px;
        cursor: pointer;
    }

    & select{
        width: 150px;
        height: 28px;
        padding: 0 6px;
        border: 1px solid ${color_lightGray};
    }

    & span{
        color: ${color_pointRed};
    }

`;

// guarantee detail
export const admin_guarantee_labelBox = styled.div`
    padding: 20px;
    background-color: ${color_white};
    border:1px solid ${color_lightGray};
    margin-bottom: 20px; 

    & div{
        display: flex;
        align-items: center;
        margin:10px 0; 
    }

    & div p{
        width: 150px;
        color:${color_darkMiddleGray};
    }

    & label{
        display: inline-block;
        width: 150px; 
        font-size: 16px;
        font-weight: ${noto_m};
    }

    & select{
        width: 600px;
        padding: 5px;
        border: 1px solid ${color_lightGray};
    }

    & input{
        width: 590px;
        padding:5px;
        border: 1px solid ${color_lightGray};
    }
`

export const admin_topSearch = styled.div`
    width:1200px;
    margin: 40px auto;

    & .admin_topSearchInner{
        width: 780px;
        display:flex;
        align-items: start;
        justify-content: space-between;
    }

    & .admin_topSearchLabel{
        padding: 15px 15px;
        border:1px solid ${color_lightGray};
        background-color:${color_bgGray};
    }

    & label{
        display:inline-block; 
        width:90px; 
    }

    & span{
        color: ${color_pointRed};
    }

    & input{
        width:220px; 
    }

    & button{
        background-color:${color_pointNavy};
        color:${color_bgGray};
        padding: 16px 16px;
    }

`;


export const admin_errorBox = styled.p`
    font-size: 12px;
    line-height: 30px; 
    color: ${color_pointNavy};
    transition: all 0.3s;
`;

//admin label img all > 이미지 첨부하는 label일 경우 사용
export const admin_labelImg = styled.div`
    background-color: ${color_white};
    border:1px solid ${color_lightGray};

    & .labelImgTit {
        font-size: 16px;
        font-weight: ${noto_m};
        padding: 20px; 
        border-bottom: 1px solid ${color_lightGray};
        margin-bottom: 20px;   
    }

    & > div{
        margin-bottom: 20xp;
        border-bottom: 1px solid ${color_lightGray}; 
        padding: 20px;
    }

    & .labelImgFirst{
        display: flex;
    }

    & .labelImgSecond{
        width: 150px;
    }

    & .labelImgInfo{
        font-size: 14px; 
        color: ${color_darkMiddleGray};
        margin: 4px 0; 
    }

    & p > span{
        color: ${color_pointRed}; 
    }

    & label{
        display: inline-block;
        width: 106px;
        height: 40px; 
        margin-top: 6px; 
        background-color: ${color_bgGray};
        border:1px solid ${ color_lightGray};
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    & img{
        width: 700px;
        height: 297px;
        object-fit: cover;
        border:1px solid ${color_lightGray}; 
    }

    & .admin_mobileImg{
        width: 340px;
        height: 272px;
    }

    & .admin_newsImg{
        width: 630px;
        height: 630px;
    }

    & .admin_ourstoryPC{
        width:700px;
        height:375px;
    }

    & .admin_ourstoryMo{
        width:394px;
        height:375px;
    }

    & .admin_optimumToolsPC{
        width:800px;
        height:300px;
    }

    & .admin_optimumToolsMo{
        width:600px;
        height:300px;
    }


`;



// 권한관리 체크박스포함
export const admin_menuPermissionBox = styled.div`
    background-color:${color_white};
    border: 1px solid ${color_lightGray};
    margin-bottom:20px; 
    

    & div{
        position: relative;
        padding: 12px 20px; 
        font-size:16px;
        line-height:16px; 
        font-weight: ${noto_b};
        color:${color_pointNavy};
        border-bottom: 1px solid ${color_lightGray};
    }

    & div:hover{
        background-color:${color_bgGray};
    }

    & div:last-child{
        border-bottom:none;
    }

    & div em{
        display: inline-block;
        position: absolute;
        top:13px;
        left:21px;
        width:21px;
        height:21px;
        border:1px solid ${color_middleGray};
    }

    & .admin_menuPermissionSub em{
        display: inline-block;
        position: absolute;
        top:13px;
        left:44px;
        width:21px;
        height:21px;
        border:1px solid ${color_middleGray};
    }

    & input[type="checkbox"]{
        display: none;
    }

    & label{
        padding-left:34px;
        line-height:24px; 
    }

    & input[type="checkbox"] + label{
        display: inline-block;
        position: relative;
        width:98%;
        height:24px; 
        margin-right:10px; 
        cursor: pointer;
    }


    & input[type="checkbox"]:checked + label::after{
        content:'✓';
        position: absolute;
        left: 0;
        top:0;
        width: 24px;
        height: 24px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: ${color_bgGray};
        background-color:${color_pointNavy};
    }
      
    & .admin_menuPermissionSub{
        font-size:14px;
        font-weight: ${noto_r};
        padding-left:44px; 
    }
`;








//-------------------- homepage --------------------
//---------- header ----------
export const headerDiv = styled.div`
    min-width: 360px;
    height: 64px;
`;

export const headerInner = styled.div`
    width:100%;
    height: 64px;
    background-color: ${color_bgGray};
    position: fixed;
    z-index: 9999;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);

    & button{
        display: inline-block;
        position: absolute;
        top: 0;
        right: 20px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 64px;
        color: ${color_darkGray};
    }

    & .blackScream{
        visibility: visible;
        width: 100%;
        height: 100vh; 
        position: absolute;
        top: 0;
        right: 0;
        background-color:rgba(0,0,0,0.8);
        transition: all 0.3s;
    }

    & .sideMenu{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: ${color_white};
        box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
        overflow-x: scroll;
        transition: all 0.4s ease-out;
    }

    & .sideMenuOpen{
        right:-400px;
    }

    & .sideMenuInner{
        display:block;
    }

    & .sideMenuTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%; 
        height: 64px;
        border-bottom: 1px solid ${color_middleGray};
    }

    & .sideMenuTop img{
        width: 128px;
        height: 30px;
        object-fit: cover;
    }

    @media screen and (min-width:400px){
        .sideMenu{
            width: 360px;
        }
    }
`;

export const headerMainLogo = styled.div`
    padding-top: 12px;
    margin-left: 20px;

    & a{
        display: inline-block;
        line-height: 40px; 
        width: 171px;
        height: 40px;
    }

    & img{
        width: 171px;
        height: 40px;
        object-fit: cover;
    }
`;

export const gnbMenu = styled.div`
    margin: 15px 20px;
    font-size: 20px;
    line-height: 20px; 
    font-weight: ${noto_m};
    border-bottom: 1px solid ${color_middleGray};

    & > div{
        margin: 6px 0;
        padding: 12px 0;
    }

    & > div > a{
        display: inline-block;
        width: 100%;
    }

    & .sideMenuFlex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & .sideMenuFlex img{
        width: 16px;
        height: 16px;
        padding: 7px 0px;
        object-fit: cover;
        transition: all 0.3s;
    }

    & .sideMenuSub{
        display: block;
        padding-left: 20px;
        margin-top: 20px; 
        transition: all 0.3s;
    }

    & .sideMenuSub a{
        display: inline-block;
        width: 100%;
        font-size: 16px; 
        color: ${color_darkMiddleGray};
        font-weight:${noto_r};
        margin:5px 0; 
    }

    & .gnbMenuIntranet{
        border-top: 1px solid ${color_middleGray};
        padding:19px 0 14px; 
    }

    & .gnbMenuIntranet a{
        font-size:14px;
        color:${color_darkMiddleGray};
    }


    & .gnbMenuIntranet .gnbMenuIntranetLogout{
        margin: 5px 0 14px; 
    }

    & .gnbMenuSns{
        display:flex; 
        border-top: 1px solid ${color_middleGray};
        padding: 20px 0; 
    }

    & .gnbMenuSns a{
        display:inline-block; 
        width: 24px; 
        height:24px;
        margin-right:14px; 
    }
        
`;


//---------- footer ----------
export const footerWrap = styled.footer`
    background-color:${color_pointNavy}; 
    width:100%; 
    min-width: 360px;

    & .footerInner{
        width:90%; 
        margin: 0 auto; 
        padding: 40px 20px ; 

        font-size: 12px;
        line-height:20px;
        color: ${color_lightGray}; 
        text-align: center;
    }
`;




//---------- main section ----------
export const home_Wrap = styled.div`
    width:100%; 
    min-width:360px; 
`;

export const home_mainSliderWrap = styled.div`
    width:100%;
    position:relative;

    & .home_mainSliderBox{
        width:100%;
        border: none;
        transition: all 0.3s; 
    }

    @media screen and (min-width: 1200px){
        & .home_mainSliderBox{
            width:100%;
        }
    }
`;

export const home_mainSliderBtns = styled.div`
    position:absolute;
    z-index:30;
    top:50%;
    width:20px;
    height:40px;
    opacity: 1;

    &.slider_left_button{
        left: 3%;
    }

    &.slider_right_button{
        right: 3%; 
    }

    & img{
        width:20px;
        height: 40px;
    }
`;



//반응형에 맞게 이미지를 다른거를 불러온다. 
export const SlideImg = styled.img`
    display:inline-block;
    width:100%;
    height:76vh; 
    border: none;
    background-image: url(${props => props.rolling_banner_image_mobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.3s; 

    @media screen and (min-width: 700px) {
        height: 90vh;
    }

    @media screen and (min-width: 1400px) {
        height: 76vh ;
        background-image: url(${props => props.rolling_banner_image});
    }

    @media screen and (min-width: 1920px) {
        height: 815px ;
    }


`; 




// -------------- 메인 섹션 시작 --------------
export const main_sectionBox = styled.section`
    width:100%;
    min-width:360px;
    transition: all 0.3s;

    & .main_sectionBoxInner{
        width:100%;
        padding:15% 0;
        margin: 0 auto;
        transition: all 0.3s;
    }

    &.printNonViewWrap{
        transition: all 0.3s;
    }

    @media screen and (min-width: 700px){
        & .main_sectionBoxInner{
            width: 700px;
            padding:80px 0;
        }
    }

    @media screen and (min-width: 1200px){

        &.printNonViewWrap{
            display:block; 
        }

        & .main_sectionBoxInner{
            width:1200px;
        }
    }
`;

export const main_sectionTit = styled.h2`
    font-size:6vw;
    line-height:1.4em;
    font-weight: ${noto_b};
    text-align:center;
    color:#333; 
    margin-bottom: 8%;

    @media screen and (min-width: 700px){
        font-size:36px;
        line-height:36px;
        margin-bottom: 60px;
    }
`;

export const main_sectionSliderWrap = styled.div`
    width: 100%;
    overflow: hidden; 
    overflow-x: scroll;
    display:flex; 

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 1200px){
        display:flex; 
        justify-content: space-between;
    }
`;

export const main_sectionSliderBox = styled.div`
    margin-right: 5%;
    transition: all 0.3s;
    padding-left: 5%;
    
    & .main_sectionSliderTit{
        font-size: 4vw;
        line-height: 1.4em;
        font-weight: ${noto_m};
        margin-bottom: 3%; 
    }

    & .main_sectionSliderImg{
        width:260px;
        height:260px; 
        overflow:hidden;
        object-fit: cover;
    }

    & img{
        width:100%;
        height:auto;
        max-height: 100%;
        opacity:1;
        transition:all 0.3s;
        
    }

    & img:hover{
        opacity:0.6;
    }

    @media screen and (min-width: 700px){
        padding-left: 0;

        & .main_sectionSliderTit{
            font-size: 16px;
            line-height: 24px;
            text-align: center; 
            margin-bottom: 10px; 
        }
    }

    @media screen and (min-width: 1200px){
        width: 290px;
        margin-right: 0;

        & .main_sectionSliderImg{
            width:290px;
            height:290px; 
        }
    }
`;



export const main_sectionStory = styled.div`
    background-color:#f3f3f3;
    width:90%;
    padding: 5%;
    margin: 0 auto; 

    & img{
        width:100%;
        height:600px; 
        object-fit: cover;
    }

    @media screen and (min-width: 700px){
        width:700px; 
    }

    @media screen and (min-width: 1200px){
        width: 1200px;
        padding: 60px 40px;

        & img{
            width:1120px;
        }
    }

`;


export const main_sectionQuick = styled.div`
    width:80%; 
    padding-bottom:10%;
    margin: 0 auto; 

    & a{
        font-size: 3.8vw;
        margin:0 auto; 
        padding: 0 5%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        height:16vw;
        border-top: 1px solid #aaa;
        color:#0F335F;
    }

    & a:last-child{
        border-bottom: 1px solid #aaa; 
    }

    & img{
        width: 8px;
        height:14px; 
    }

    @media screen and (min-width: 700px){
        width:700px; 
        display: flex;
        flex-wrap: wrap;

        & a{
            width: 300px; 
            font-size: 14px;
            padding: 0 10px;
            height:60px;
        }

        & a:nth-child(3){
            border-bottom: 1px solid #aaa; 
        }
    }
`;



//---------- main section - login ----------
export const main_loginWrap = styled.div`
    width: 360px; 
    padding: 40px 0; 
    margin:0 auto;

    & .main_loginTit{
        font-size: 12px;
        line-height:20px;
        text-align:center;
        color: ${color_pointNavy}; 
        margin-bottom: 40px; 
    }

    & .main_loginTit h2{
        font-size: 28px;
        line-height: 30px;
        font-weight: ${noto_m};
        color: ${color_darkGray};
        margin-bottom:12px;
    }
    
    & .main_loginBox{
        border: 1px solid ${color_lightGray};
        padding:14px;
        margin-bottom:10px;  

    }

    & .main_loginBox label{
        display:inline-block;
        width:80px; 
    }

    & input{
        width:70%;
    }
`;




//---------- main section - editorView ----------
export const editorView_mainWrap = styled.div`
    width: 100%;
    min-width: 360px;
    padding: 60px 0;

    & .editorView_mainInner{
        width:90%;
        margin: 0 auto;
        overflow:hidden; 
    }

    & .editorView_mobile{
        display:block;
        transition:all 0.3s; 
    }

    & .editorView_mobile img{
        width:100%;
        height:auto;
    }

    & .editorView_pc{
        display:none;
        transition:all 0.3s; 
    }

    & .editorView_pc img{
        width:100%;
        height:auto;
    }



    @media screen and (min-width: 769px){
        & .editorView_mobile{
            display:none;
        }
    
        & .editorView_pc{
            display:block;
        }
    }

    @media screen and (min-width: 1200px){
        & .editorView_mainInner{
            width:1200px;
        }
    }

`;


//---------- main section - editorView - Board 파일첨부 ----------
export const editorView_archiveData = styled.div`
    margin-top:10%;
    border-top:1px solid ${color_lightGray};
    border-bottom:1px solid ${color_lightGray};

    .editorView_archiveDataTit{
        font-size: 3.8vw;
        line-height:1.2em; 
        border-bottom: 1px solid ${color_lightGray};
        padding: 2% 0;
        transition:all 0.3s;
    }

    & a{
        display:inline-block;
        width:94%; 
        margin: 1.5% 3%;        
        font-size:3.3vw;
        line-height:1.3em;
        font-weight:${noto_b};
        cursor: pointer;
        transition:all 0.3s;
    }

    @media screen and (min-width: 700px){
        max-width:1200px; 
        margin-top:40px;

        .editorView_archiveDataTit{
            font-size: 20px;
            line-height:28px; 
            padding: 10px 0;
        }
    
        & a{
            margin:10px 15px;        
            font-size:16px;
            line-height:20px;
        }
    }

`;












//---------- main section - news&event main ----------
export const newsEventMain = styled.div`
    transition: all 0.3s;

    & .newsEventMainTit{
        font-size: 26px; 
        line-height: 26px; 
        text-align: center;
        margin-bottom: 60px; 
    }

    .newsEventMainBox{
        width: 100%;
        margin-bottom: 12%; 
        transition: all 0.3s;
    }

    & .newsEventMainBoxImg{
        width: 100%;
        height: 90vw;
        transition: all 0.3s;
    }

    & .newsEventMainBoxImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .newsEventMainBoxTit{
        font-size: 5.5vw;
        line-height: 1.4em;
        font-weight: ${noto_m};
        margin: 20px 0 10px;
        transition: all 0.3s;
    }

    & .newsEventMainBoxSubTit{
        font-size: 4.4vw;
        line-height: 1.4em;
        color: ${color_pointNavy};
        transition: all 0.3s;
    }


    @media screen and (min-width:700px){
        & .newsEventMainWrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        & .newsEventMainBox{
            width: 46%;
            margin:0 2% 14%;
        }

        & .newsEventMainBoxImg{
            width: 100%;
            height: 41.4vw;
        }

        & .newsEventMainBoxImg img{
            width: 100%;
            height: 100%;
        }

        & .newsEventMainBoxTit{
            font-size:28px;
            line-height:36px;
        }

        & .newsEventMainBoxSubTit{
            font-size: 16px;
            line-height: 22px;
        }
    }

    @media screen and (min-width:1200px){
        & .newsEventMainBox{
            width: 380px;
            margin:0 10px 50px; 
        }

        & .newsEventMainBoxImg{
            width: 380px;
            height: 380px;
        }
    }
`;


export const newsEventDetail = styled.div`
    & .newsEventDetailBox{
        margin-bottom: 40px;
        text-align: center;
    }

    & .newsEventDetailRegiste{
        font-size: 12px;
        line-height: 1.4em;
        color: ${color_darkMiddleGray};
    }

    & .newsEventDetailTit{
        font-size: 28px;
        line-height: 1.4em;
        margin: 20px 0; 
    }

    & .newsEventDetailSubTit{
        font-size: 18px;
        line-height: 1.4em;
        color: ${color_middleGray}; 
    }

    @media screen and (min-width:1200px){
        & .newsEventDetailTit{
            font-size: 32px;
            line-height: 40px;
        }
    }
`;



//---------- main section _ guarantee & validate print page ----------
export const printMainTitBox = styled.div`
    padding: 30px;
    text-align: center;

    & .printMainTit{
        font-size: 26px; 
        line-height: 26px;
        margin: 0 auto 10px;
        font-weight: ${noto_m};
    }

    & .printMainSubTit{
        font-size: 14px;
        color: ${color_pointNavy};
    }
`;

export const printTopBarWrap = styled.div`
    width:100%;
    padding: 40px 0; 
    border: 1px solid ${color_lightGray};
    margin-bottom:40px; 

    & .printTopBarInner{
        width: 794px;
        margin:0 auto; 
    }

    & .printTopBarTit{
        margin-bottom:14px;
        color:${color_darkMiddleGray};   
    }

    & .guaranteePrintTopBarInner{
        width:960px;
        margin:0 auto; 
    }
   
`;

export const printTopBarSearchbox = styled.div`
    display:flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom:20px; 

    & .printTopBarSearchLabel{
        padding: 15px 15px;
        border:1px solid ${color_lightGray};
        background-color:${color_bgGray};
    }

    & label{
        display:inline-block; 
        width:90px; 
    }

    & span{
        color: ${color_pointRed};
    }

    & input{
        width:220px; 
    }

    & .printTopBarSearchError{
        margin-left:105px;
        margin-top:5px; 
        
        color:${color_pointNavy};
        font-size:12px; 
        transition:all 0.3s;
    }
`;

export const printTopBarSearchBtn = styled.button`
    width: 80px;
    height: 50px;
    color: ${color_white};
    cursor: pointer;


    &.printTopBarSearchBtn_search{
        background-color: ${color_pointNavy};
        border: 1px solid ${color_pointNavy};
    }

    &.printTopBarSearchBtn_print{
        background-color: ${color_darkGray};
        border: 1px solid ${color_darkGray};
    }

    &.printTopBarSearchBtn_change{
        color: ${color_darkGray};
        background-color: ${color_bgGray};
        border: 1px solid ${color_middleGray};
    }

    &.printTopBarSearchBtn_searchValidate{
        display:block;
        margin:3% auto 5%;
        background-color: ${color_pointNavy};
        border: 1px solid ${color_pointNavy};
    }

    @media screen and (min-width:794px){
        /* 정품확인 검색버튼 - pc */
        &.printTopBarSearchBtn_searchValidate{
            display:inline;
            margin:0; 
        }
    }
`;


/* 정품확인 버튼 모음 */
export const validatePrintBtnBox = styled.div`

    & button{
        width: 80px;
        height: 50px;
        color: ${color_white};
        cursor: pointer;
    }

    /* 정품확인 출력버튼 - 모바일 */
    & .printTopBarSearchBtn_printValidate{
        display:none;
    }

    /* 정품확인 미리보기 - 모바일 */
    & .printTopBarSearchBtn_previewValidate{
        display:block;
        margin-left:auto;
        margin-right:auto;
        margin-bottom:3%; 
        background-color: ${color_darkGray};
        border: 1px solid ${color_darkGray};
    }

    @media screen and (min-width:794px){
        /* 정품확인 출력버튼 - pc */
        & .printTopBarSearchBtn_printValidate{
            display:block;
            background-color: ${color_darkGray};
            border: 1px solid ${color_darkGray};
        }

        /* 정품확인 미리보기 - pc */
        & .printTopBarSearchBtn_previewValidate{
            display:inline;
            margin-left:0;
            margin-right:0;
            margin-bottom:10px; 
        }
    }


`;




export const guranteePrintTopBarSearchbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:30px;

    & .printTopBarTit{
        margin-bottom:0;
        color: ${color_darkGray};
    }

    & button{
        margin-left:10px; 
    }
`;


export const printNonViewBox = styled.div`
    display:block;
    width: 100%;
    min-width:360px;
    padding:150px 30px;
    margin:0 auto; 
    text-align:center;
    font-size:20px; 
    color: ${color_darkMiddleGray};
    line-height:30px; 
    transition: all 0.3s; 

    @media screen and (min-width:1200px){
        display:none;
    }

`;



// 보증서 발급
export const GuaranteeAddWrap = styled.div`
    width:900px;
    margin:0 auto; 
    
    & button{
        display:block; 
        color: ${color_white};
        padding:16px 60px;
        margin: 30px auto;
        background-color: ${color_pointNavy};
        border: 1px solid ${color_middleGray};
        cursor: pointer;
    }
`;

export const GuaranteeAddBox = styled.div`
    padding:20px;
    border:1px solid ${color_lightGray};
    margin-bottom:20px;
        
    & .GuaranteeAddBoxMore > div{
        margin: 10px 0; 
    }

    &  .GuaranteeAddBoxMore > div > p{
        display:inline-block;
        width:140px;
        color:${color_darkMiddleGray};
        padding:8px 0;
        margin-right:1%;
    }

    & label{
        display:inline-block;
        width:140px;
        padding:8px 0;
        margin-right:1%;
        font-weight: ${noto_b};
    }

    & label span{
        color: ${color_pointRed};
        font-style: normal;
    }

    & input{
        display:inline-block;
        width:690px;
        color: ${color_darkGray};
        padding:8px;
        background-color: ${color_bgGray};
        box-sizing: border-box;
    }

    & .GuaranteeAddDate{
        position:relative;
    }

    & .GuaranteeAddDate::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        top: 5px;
        width:20px;
        height:20px; 
        transform: translateX(-10px);
        padding-left: 3000px;
        cursor: pointer;
    }

    & select{
        width:690px;
        padding:8px;
        color: ${color_darkGray};
        border:none; 
        background-color: ${color_bgGray};
    }

`;



// 정품 확인 (모바일 보여야해서 따로 작성)
export const main_validateWrap = styled.section`
    width:100%;
    min-width:360px;
    transition: all 0.3s;

    & .main_validateInner{
        width: 100%;
        padding:15% 0;
        margin: 0 auto;
        transition: all 0.3s;
    }

    @media screen and (min-width: 794px){
        & .main_validateInner{
            padding:3% 0;
        }

        & .main_sectionBoxInner{
            width:794px;
        }
    }
`;


export const validatePrintTopBarWrap = styled.div`
    width:90%;
    padding: 40px 20px 5px; 
    border: 1px solid ${color_lightGray};
    margin:0 auto 40px;

    & .printTopBarInner{
        width: 100%;
        margin:0 auto; 
    }

    & .printTopBarTit{
        margin-bottom:14px;
        color:${color_darkMiddleGray};   
    }



    @media screen and (min-width:794px){
        width:794px;
        padding: 40px 0 20px; 
        border: 1px solid ${color_lightGray};
        margin-bottom:40px; 

        & .printTopBarInner{
            width: 96%;
            margin:0 auto;
        }

        & .printTopBarTit{
            margin-bottom:14px;
            color:${color_darkMiddleGray};   
        }

    }
`;


export const validatePrintTopBarSearchbox = styled.div`
    width:100%;

    & .printTopBarSearchLabel{
        font-size:3vw;
        padding: 3%;
        border:1px solid ${color_lightGray};
        background-color:${color_bgGray};
    }

    & label{
        font-size:3vw;
        display:inline-block; 
        width:20%;
    }

    & span{
        color: ${color_pointRed};
    }

    & input{
        font-size:3vw;
        width:60%;
    }

    & .printTopBarSearchError{
        margin-left:22%;
        margin-top:2%; 
        
        color:${color_pointNavy};
        font-size:2.8vw; 
        transition:all 0.3s;
    }


    @media screen and (min-width:794px){
        display:flex;
        align-items: start;
        justify-content: space-between;
        margin-bottom:20px; 

        & .printTopBarSearchLabel{
            font-size:14px;
            padding: 15px 15px;
            border:1px solid ${color_lightGray};
            background-color:${color_bgGray};
        }

        & label{
            font-size:14px;
            display:inline-block; 
            width:80px; 
        }

        & span{
            color: ${color_pointRed};
        }

        & input{
            font-size:14px;
            width:210px; 
        }

        & .printTopBarSearchError{
            margin-left:105px;
            margin-top:5px; 
            font-size:12px; 
        }
    }
`;

export const validatePrintTopBarSearchResult = styled.div`
    display:flex;
    flex-direction: column;
    border-top: 2px solid ${color_darkGray};
    
    &:last-child{
        border-bottom: 2px solid ${color_darkGray};
    }

    & .printTopBarSearchResult_left > div {
        display:flex; 
        width:100%; 
        padding: 3%;
    }

    & .printTopBarSearchResult_left > div:nth-child(2){
        border-top:1px solid ${color_middleGray};
        border-bottom: 1px solid ${color_middleGray};
    }

    & .printTopBarSearchResult_left > div p{
        font-size: 3vw;
        line-height:1.2em;
    }

    & .printTopBarSearchResult_left > div p:first-child{
        width: 20%; 
        font-weight: ${noto_m};
    }

    & .printTopBarSearchResult_left > div p:nth-child(2){
        width: 82%;
    }


    @media screen and (min-width:794px){
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid ${color_darkGray};
        
        
        & .printTopBarSearchResult_left > div {
            width:660px; 
            padding: 14px 0;
        }

        & .printTopBarSearchResult_left > div p{
            font-size: 14px;
            line-height:20px;
        }

        & .printTopBarSearchResult_left > div p:first-child{
            width: 100px;
            padding-left:24px; 
            
        }
    }

`;

export const validatePrintWrap= styled.div`
    min-width: 360px;
    padding:80px 0;
    height:100%; 
    background-color:#eee;
    font-size:3.3vw;
    line-height:1.4em
    transition: all 0.3s;

    & .validatePrintWrapInner{
        width:100%;
        height:150vw;
        position:relative;
        margin: 0 auto;
    }
    
    & .validatePrintBox{
        position:absolute; 
        top: 0; 
        left: 0; 
    }

    & .guatanteeImg{
        width:100%;
        height:auto;
    }

    & .guatanteeImg .guatanteeImgPC{
        display:none;
    }
        
    & .guatanteeImg img{
        width:100%;
        height:auto;
        object-fit: cover;
    }
    
    & .validateP_customerName{
        position:absolute;
        top:44.6%;
        left:36%;
    }
    
    & .validateP_carNum{
        position:absolute;
        top:48.4%;
        left:36%;
    }
    
    & .validateP_pointName{
        position:absolute;
        top:52.2%;
        left:36%;
    }
    
    & .validateP_product{
        position:absolute;
        top:55.9%;
        left:36%;
        width:48%;
        line-height:1.2em; 
    }

    @media screen and (min-width: 794px){    
        font-size:18px;

        & .validatePrintWrapInner{
            width:794px;
            height:1120px;
        }

        & .guatanteeImg .guatanteeImgPC{
            display:block;
        }

        & .guatanteeImg .guatanteeImgMO{
            display:none;
        }

        & .validateP_customerName{
            top:509px;
            left:280px;
        }
        
        & .validateP_carNum{
            top:545px;
            left:280px;
        }
        
        & .validateP_pointName{
            top:585px;
            left:280px;
        }
        
        & .validateP_product{
            top:616px;
            left:280px;
            width: 328px; 
            line-height:26px; 
        }
    }


`;




//---------- main section _ OC Point ----------
export const mapSearchWrap = styled.div`
    width:100%;
    transition: all 0.3s; 

    @media screen and (min-width:800px){
        display: flex; 
    }
`;

export const mapSearchListWrap = styled.div`
    width:100%;
    min-width:360px;
    height:100vh;
    border-right: 1px solid ${color_lightGray};
    border-bottom: 1px solid ${color_lightGray};


    @media screen and (min-width:800px){
        width:400px;
    }
`;

export const mapSearchListBox = styled.div`
    height: 190px;
    background-color: ${color_white};
    padding:40px 20px 0;
    
    & h2{
        font-size: 20px;
        font-weight: ${noto_m};
        line-height:24px;
        margin-bottom:24px;  
    }

    & select{
        width:34%;
        height:50px;
        color:${color_darkMiddleGray};
        border: 1px solid ${color_lightGray};
        background-color: ${color_white}; 
        border-radius: 0px;
        padding: 0 10px; 
        margin-right:4%; 
        box-sizing: border-box;
    }

    & input{
        width:60%;
        height:50px;
        border: 1px solid ${color_lightGray};
        background-color: ${color_white}; 
        padding: 0 10px; 
        box-sizing: border-box;
    }
`;


export const mapSearchListChoiceBox = styled.div`
    display:flex;
    height: 50px;
    
    & div{
        width:25%;
        height:50px;
        text-align:center;
        font-size: 14px;
        line-height:50px; 
        color: ${color_pointNavy};
        border-top: 1px solid ${color_lightGray};
        border-bottom: 1px solid ${color_lightGray};
        border-right: 1px solid ${color_lightGray};
    }

    & div:last-child{
        border-right: none;
    }

    & div.click{
        background-color:${color_pointNavy};
        border-top: 1px solid ${color_pointNavy};
        border-bottom: 1px solid ${color_pointNavy};
        color:${color_bgGray};
    }

    & div.nonClick{
        background-color:${color_bgGray};
    }
`;

export const mapSearchListChoiceListBox = styled.div`
    height: calc(100vh - 240px);
    overflow-y: scroll;
   
    &::-webkit-scrollbar{
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`;


export const mapSearchListChoiceList = styled.div`
    padding:24px;
    border-bottom: 1px solid ${color_lightGray};

    & .mapChoiceTit{
        font-size:18px;
        font-weight: ${noto_m};
        color: ${color_pointNavy};
    }

    & .mapChoiceTit span{
        font-weight: ${noto_b};
    }

    & > div{
        padding-top:34px; 
        font-size: 16px;
        line-height:24px; 
        color: ${color_darkGray};
    }

    & > div > div{
        display:flex;
    }

    & > div > div:nth-child(1){
        margin-bottom:10px; 
    }
    
    & .mapChoiceInfoTit{
        min-width:70px; 
        font-size:14px;
        font-weight: ${noto_m};
    }

`;



export const mapSearchMapWrap = styled.div`
    width:90%;
    margin: 20px auto; 
   
    @media screen and (min-width:1200px){
        width:100%;
        height:100vh;
        margin: 0 0 ; 
    }
`;



// kakaoMap.js에 있는 카카오맵 코드
export const kakaomapWrap = styled.div`
    position:relative;

    & .kakaomapBox{
        width:100%;
        height:500px;
    }

    & .kakaomapZoomBox{
        position:absolute;
        top:2%;
        right:2%;
        z-index:2;
    }

    & .kakaomapZoomBox p{
        width:40px; 
        height:40px; 
        background-color: ${color_white};
        border: 1px solid ${color_lightGray};
    }

    & .kakaomapZoomBox p img{
        width:20px;
        height:20px; 
        object-fit: cover;
        padding:10px;
    }



    @media screen and (min-width:1200px){
        height:100vh;

        & .kakaomapBox{
            height:100vh; 
        }
    }
`;


//optimum tools
export const optimumToolBoxWrap = styled.div`
    width:100%;
    margin:0 auto; 

    & .optimumToolBox{
        width:94%;
        padding:10px;
        margin: 0 auto 30px; 
        background-color:${color_bgGray};
    }

    & .optimumToolImg{
        width:100%;
        height:300px;
        overflow:hidden;
        margin:0 auto; 
        display:flex;
        justify-content:center;
        align-items:center;
    }

    & .optimumToolImg img{
        width:100%;
        height:auto;
        min-height:100%; 
        object-fit: cover;
    }

    & .optimumToolTit{
        font-size:14px;
        line-height:20px;
        color: ${color_pointNavy};
        margin-top: 2%; 
    }

    @media screen and (min-width:820px){
        width: 820px;

        & .optimumToolBox{
            width:100%;
        }

        & .optimumToolTit{
            font-size:18px;
            line-height:30px;
            margin-top: 8px; 
        }
    }

`;



//---------- main _ Board ----------
export const home_boardWrap = styled.div`
    min-width:360px;
    margin:15% auto; 

    .home_boardInner{
        border:1px solid ${color_lightGray};
        padding: 5%;
        margin: 3%;
    }

    .home_boardInnerTit{
        font-size:5.5vw;
        margin-bottom:5%;
    }

    .home_boardBox{
        width:100%;
    }

    .home_boardNodate{
        font-size:3.8vw;
        text-align:center;
        padding:10%;
        background-color:${color_bgGray};
    }

    @media screen and (min-width:700px){
        margin:60px auto; 
        max-width:1000px;

        .home_boardInner{
            padding: 24px;
            margin:15px;
        }

        .home_boardInnerTit{
            font-size:24px;
            margin-bottom:25px;
        }

        .home_boardNodate{
            font-size:16px;
            padding:40px;
        }
    }
`;
