//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React from 'react';
import * as H from '../../Styles/StyleComponentHan';

export function Footer() {  

  return (
    <H.footerWrap>
      <div className="footerInner">
        <p>옵티멈 썬팅필름 / 대표이사 박병열 </p>
        <p>Address 광주광역시 북구 임동 59-2</p>
        <p>Contact us +82-62-527-5588</p>
        <p>Copyright © 코리아틴터그룹. All Rights Reserved.</p>
      </div>
    </H.footerWrap>

  );
}

export default Footer;